import store from 'store'

/**
 * Verifica si el usuario actual tiene todos los roles requeridos.
 * @param {string | Array<string>} required Roles requeridos.
 * @param {'AND' | 'OR'} operator Operador de comprobación de roles. Solo los roles requeridos esta en un arreglo.
 * @returns Retorna true si el usuario tiene los roles requeridos.
 */
export const verifyAccess = (required, operator = 'AND') => {
    const roles = store.getState()?.auth?.roles ?? []
    const roleNames = Array.isArray(required) ? required : required ? [required] : []
    
    // Si roleNames está vacío, retorna true de inmediato
    if (roleNames.length === 0) {
        return true
    }

    const verifyAll = operator === 'AND'

    // Si verifyAll es true, verificar que el usuario tenga TODOS los roles.
    if (verifyAll) {
        for (const roleName of roleNames) {
            if (!roles.includes(roleName)) {
                // Si el usuario no tiene uno de los roles, retorna false
                return false
            }
        }
        return true // El usuario tiene todos los roles
    }

    // Si verifyAll es false, verificar que el usuario tenga AL MENOS un rol.
    for (const roleName of roleNames) {
        if (roles.includes(roleName)) {
            return true // El usuario tiene al menos uno de los roles
        }
    }

    return false // El usuario no tiene ninguno de los roles
}
