import Header from 'Component/Header'
import DatosPersona from './components/DatosPersona/DatosPersona'
import { Button } from 'modules/modular/Button'
import { useEffect, useRef, useState } from 'react'
import DatosCuenta from './components/DatosCuenta/DatosCuenta'
import DatosRecibo from './components/DatosRecibo'
import { DataTable } from 'modules/modular/DataTable'
import RecaudacionesServices from 'services/recaudaciones.service'
import useHttpToast from 'hooks/useHttpToast'
import LastVenta from './components/LastVenta'

export default function VentaValores() {
    const { showHttpToast } = useHttpToast()
    const inputRefs = useRef({})
    const datosReciboRef = useRef(null)
    const datosPersonaRef = useRef(null)
    const datosCuentaRef = useRef(null)
    const [totales, setTotales] = useState({ cuenta: 0, cuentas_asociadas: 0 })
    const [lastVentas, setLastVentas] = useState([])
    const [lastPersona, setLastPersona] = useState(null)
    const [submitting, setSubmitting] = useState(false)

    useEffect(() => {
        const load = async () => {
            const { status, data } = await RecaudacionesServices.ventaValores.ultimas()
            if (status === 200) {
                setLastVentas(data.data.movimientos)
                setLastPersona(data.data.ultima_persona)
                datosReciboRef.current?.setRecibo({
                    numero_recibo: data.data.numero_recibo,
                    preimpreso: data.data.preimpreso,
                })
            } else {
                showHttpToast(status, { detail: data.message })
            }
        }
        load()
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setSubmitting(true)
        await handleSell()
        setSubmitting(false)
    }
    const handleSell = async () => {
        if (!datosPersonaRef.current || !datosReciboRef.current || !datosCuentaRef.current) {
            return
        }
        const hasErrorsPersona = datosPersonaRef.current.validatePersona()
        const hasErrorsRecibo = datosReciboRef.current.validateRecibo()
        const hasErrorsCuenta = datosCuentaRef.current.validateCuenta()
        if (hasErrorsPersona || hasErrorsRecibo || hasErrorsCuenta) {
            return
        }
        const persona = datosPersonaRef.current.getPersona()
        const cuenta = datosCuentaRef.current.getCuenta()
        const recibo = datosReciboRef.current.getRecibo()
        console.log(persona)
        console.log(cuenta)
        console.log(recibo)
    }

    const resetForm = () => {
        datosPersonaRef.current?.resetPersona()
        datosCuentaRef.current?.resetCuenta()
        inputRefs.current['persona.documento']?.focus()
    }

    const saveRef = (el, name) => {
        inputRefs.current[name] = el
    }

    return (
        <div>
            <Header title='VENTA DE VALORES' />
            <div className='d-flex flex-column align-items-center'>
                <div className='container shadow-sm p-4 bg-body rounded'>
                    <form
                        onSubmit={handleSubmit}
                        onKeyDown={(e) => {
                            if (e.target.tagName !== 'BUTTON') {
                                e.key === 'Enter' && e.preventDefault()
                            }
                        }}
                        noValidate
                    >
                        <DatosPersona
                            innerRef={datosPersonaRef}
                            inputRefs={inputRefs}
                            saveRef={saveRef}
                            lastPersona={lastPersona}
                            className='mb-3'
                        />
                        <DatosCuenta
                            innerRef={datosCuentaRef}
                            inputRefs={inputRefs}
                            saveRef={saveRef}
                            updateTotales={setTotales}
                            className='mb-3'
                        />
                        <DatosRecibo
                            innerRef={datosReciboRef}
                            inputRefs={inputRefs}
                            totales={totales}
                            className='mb-3'
                        />
                        <div className='bg-blue py-3 text-center rounded'>
                            <Button
                                ref={(el) => saveRef(el, 'buttonTerminar')}
                                type='submit'
                                startIcon={<span className='bi-floppy' />}
                                loading={submitting}
                            >
                                Terminar
                            </Button>
                            <Button
                                variant='secondary'
                                onClick={resetForm}
                                className='ms-3'
                                startIcon={<span className='bi-x-lg' />}
                                disabled={submitting}
                            >
                                Limpiar
                            </Button>
                        </div>
                    </form>
                </div>
                <LastVenta movimientos={lastVentas} />
            </div>
        </div>
    )
}
