import { GenericRequest } from './request/request'

const RecaudacionesServices = {
    persona: {
        list: (data, params) => GenericRequest.post('recaudaciones/list-persona', data, params),
        update: (id, data) => GenericRequest.post('recaudaciones/update-persona', data, { id }),
    },
    ventaValores: {
        listCuentas: (params = {}) => GenericRequest.get('venta-valores/list-cuentas', params),
        listCuentasAsociadas: (id) => GenericRequest.get('venta-valores/list-cuentas-asociadas', { id }),
        ultimas: () => GenericRequest.get('venta-valores/ultimas'),
    },
    reporteValorados: {
        consolidado: {
            porUnidadCuenta: (params) => GenericRequest.post('reporte-valorados/consolidado-por-unidad-cuenta', params),
            porConcepto: (params) => GenericRequest.post('reporte-valorados/consolidado-por-concepto', params),
            porUnidad: (params) => GenericRequest.post('reporte-valorados/consolidado-por-unidad', params),
            institucional: (params) => GenericRequest.post('reporte-valorados/consolidado-institucional', params),
        },
        recaudaciones: {
            segunRecibo: (params) => GenericRequest.post('reporte-valorados/recaudaciones-segun-recibos', params),
            porCuenta: (params) => GenericRequest.post('reporte-valorados/recaudaciones-por-cuenta', params),
            porTipoEstudiante: (params) =>
                GenericRequest.post('reporte-valorados/recaudaciones-por-tipo-estudiante', params),
        },
        cobroComisionBancaria: (params) => GenericRequest.post('reporte-valorados/cobro-comision-bancaria', params),
        distribucionAportes: (params) => GenericRequest.post('reporte-valorados/distribucion-aportes', params),
        recibosAnulados: (params) => GenericRequest.post('reporte-valorados/recibos-anulados', params),
        valores: (params) => GenericRequest.post('reporte-valorados/valores', params),
        resumenRecibos: (params) => GenericRequest.post('reporte-valorados/resumen-recibos', params),

        // Servicios para filtros de reportes
        listEstructuras: (params = {}) => GenericRequest.get('reporte-valorados/list-estructuras', params),
        listUsuarios: (params = {}) => GenericRequest.get('reporte-valorados/list-usuarios', params),
        listRubros: (params = {}) => GenericRequest.get('clasificador/list-rubros', params),
        listConceptos: (params = {}) => GenericRequest.get('clasificador/list-conceptos', params),
        listUnidades: (params = {}) => GenericRequest.get('reporte-valorados/list-unidades', params),
        listCuentas: (params = {}) => GenericRequest.get('reporte-valorados/list-cuentas', params),
    },
}

export default RecaudacionesServices
