import { forwardRef, useImperativeHandle } from 'react'

export const DatosPersonaRef = forwardRef(({ validate, reset, get }, ref) => {
    useImperativeHandle(ref, () => ({
        validatePersona() {
            return validate()
        },
        resetPersona() {
            reset()
        },
        getPersona() {
            return get()
        },
    }))
    return null
})
