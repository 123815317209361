import { initialPagination } from 'data/constants'
import useFilters, { objectToQueryString } from 'hooks/useFilters'
import useHttpToast from 'hooks/useHttpToast'
import { Button } from 'modules/modular/Button'
import { ButtonIcon } from 'modules/modular/ButtonIcon'
import { Checkbox } from 'modules/modular/Checkbox'
import { DataTable } from 'modules/modular/DataTable'
import { EditableSelect } from 'modules/modular/EditableSelect'
import { FieldGroup } from 'modules/modular/FieldGroup'
import { Input } from 'modules/modular/Input'
import { Confirm, Modal } from 'modules/modular/Modal'
import { Select } from 'modules/modular/Select'
import { classNames } from 'modules/modular/utils'
import { useEffect, useMemo, useState } from 'react'
import PresupuestosServices from 'services/presupuestos.service'
import { formatDate, pick } from 'utils'

const currentDate = formatDate(new Date())

const initialValues = {
    objeto_gasto: '',
    nombre: '',
    fecha_alta: currentDate,
    fecha_baja: '',
}
const checkboxFilterElement = ({ value, filterCallback }) => (
    <div className='d-flex justify-content-center'>
        <Checkbox
            value={value}
            onChange={(e) => filterCallback(e.target.value)}
            triState
            style={{ fontSize: '1.5rem' }}
        />
    </div>
)
const filterOptions = { contain: ['codigo', 'nombre', 'clase_gasto'], bool: ['activo'] }

export default function ItemsGasto() {
    const { showHttpToast } = useHttpToast()
    const { filters, handleFilter, handlePage, handlePageSize, handleSort, reloadFilters } = useFilters({
        filterOptions,
    })
    const [items, setItems] = useState([])
    const [pagination, setPagination] = useState(initialPagination)
    const [isVisible, setIsVisible] = useState({
        modalForm: false,
        modalConfirmDelete: false,
    })
    const [errors, setErrors] = useState({})
    const [submitting, setSubmitting] = useState(false)
    const [values, setValues] = useState(initialValues)
    const [objetosGasto, setObjetosGasto] = useState([])
    const [clasesGasto, setClasesGasto] = useState([])

    useEffect(() => {
        const loadClasesGasto = async () => {
            const { status, data } = await PresupuestosServices.claseGasto.list({
                all: true,
                sort: 'codigo',
                'filter[activo]': 1,
            })
            if (status === 200) {
                setClasesGasto(data.data)
            } else {
                showHttpToast(status, { detail: 'No se pudo cargar las clases de gasto' })
            }
        }
        loadClasesGasto()
    }, [])
    useEffect(() => {
        const loadList = async () => {
            const params = objectToQueryString(filters)
            const { status, data } = await PresupuestosServices.itemGasto.list(params)
            if (status === 200) {
                setItems(data.data)
                setPagination(data.pagination)
            } else {
                showHttpToast(status, { detail: data.message })
            }
        }
        loadList()
    }, [filters])

    const show = (name) => setIsVisible((prev) => ({ ...prev, [name]: true }))
    const hide = (name) => setIsVisible((prev) => ({ ...prev, [name]: false }))

    const handleAction = (action, rowData = null) => {
        if (action === 'toggleActive') {
            handleToggleActive(rowData)
        } else {
            if (action === 'update') {
                show('modalForm')
                handleSearchObjetosGasto(rowData.codigo)
                setValues({
                    ...pick(rowData, ['id', 'nombre', 'fecha_alta', 'clase_gasto_id']),
                    fecha_baja: rowData.fecha_baja ?? '',
                    objeto_gasto: {
                        partida: rowData.codigo,
                        clase_gasto_id: rowData.clase_gasto_id,
                    },
                })
            } else if (action === 'delete') {
                setValues(rowData)
                show('modalConfirmDelete')
            }
        }
    }

    const optionsTemplate = ({ data }) => (
        <div className='text-center'>
            <ButtonIcon
                onClick={() => handleAction('update', data)}
                title='Editar'
                style={{ fontSize: '1.2rem' }}
                disabled={!data.activo}
            >
                <span className='bi-pencil lh-1' style={{ fontSize: '1rem' }} />
            </ButtonIcon>
            <ButtonIcon
                variant={data.activo ? 'danger' : 'success'}
                className='ms-2'
                onClick={() => handleAction('toggleActive', data)}
                title={data.activo ? 'Desactivar' : 'Activar'}
                style={{ fontSize: '1.2rem' }}
            >
                <span
                    className={classNames(['lh-1', data.activo ? 'bi-lock' : 'bi-unlock'])}
                    style={{ fontSize: '1rem' }}
                />
            </ButtonIcon>
        </div>
    )
    const columns = useMemo(
        () => [
            { field: 'codigo', header: 'Partida', sortable: true, filter: true, style: { width: '6rem' } },
            { field: 'nombre', header: 'Detalle', sortable: true, filter: true, className: 'w-25' },
            {
                field: 'clase_gasto',
                header: 'Clase de gasto',
                cell: ({ data }) => `${data.clase_gasto_codigo} - ${data.clase_gasto}`,
                sortable: true,
                filter: true,
                className: 'w-25',
            },
            { field: 'fecha_alta', header: 'Fecha alta', className: 'text-center' },
            { field: 'fecha_baja', header: 'Fecha baja', default: 'INDEFINIDO', className: 'text-center' },
            {
                field: 'activo',
                header: 'Activo',
                cell: ({ value }) => (value ? 'Si' : 'No'),
                className: 'text-center',
                filter: true,
                filterElement: checkboxFilterElement,
                style: { width: '6rem' },
            },
            {
                id: 'options',
                header: 'Opciones',
                headerClassName: 'text-center',
                headerStyle: { width: '10rem' },
                cell: optionsTemplate,
            },
        ],
        [],
    )

    const validateValues = (values) => {
        const _errors = {}
        if (!values.objeto_gasto?.partida) _errors.objeto_gasto = 'Requerido'
        if (!values.nombre) _errors.nombre = 'Requerido'
        if (!values.clase_gasto_id) _errors.clase_gasto_id = 'Requerido'
        if (!values.fecha_alta) _errors.fecha_alta = 'Requerido'
        return {
            hasErrors: Object.values(_errors).length > 0,
            errors: _errors,
        }
    }
    const handleChangeValues = (e) => {
        const name = e.target.name
        const value = name === 'nombre' ? e.target.value.toUpperCase() : e.target.value
        if (name === 'objeto_gasto' && value?.clase_gasto_id) {
            setValues((prev) => ({ ...prev, [name]: value, clase_gasto_id: value.clase_gasto_id }))
            if (errors[name] || errors.clase_gasto_id) {
                setErrors((prev) => ({ ...prev, [name]: '', clase_gasto_id: '' }))
            }
        } else {
            setValues((prev) => ({ ...prev, [name]: value }))
            errors[name] && setErrors((prev) => ({ ...prev, [name]: '' }))
        }
    }
    const handleSubmit = async () => {
        setSubmitting(true)
        const { hasErrors, errors } = validateValues(values)
        if (hasErrors) {
            setErrors(errors)
        } else {
            const _values = {
                ...pick(values, ['nombre', 'clase_gasto_id', 'fecha_alta', 'fecha_baja']),
                codigo: values.objeto_gasto.partida,
            }
            if (values.id) {
                await handleUpdate(values.id, _values)
            } else {
                await handleCreate(_values)
            }
        }
        setSubmitting(false)
    }
    const handleCreate = async (values) => {
        const { status, data } = await PresupuestosServices.itemGasto.create(values)
        showHttpToast(status, { detail: data.message })
        if (status === 201) {
            hide('modalForm')
            reloadFilters()
        }
    }
    const handleUpdate = async (id, values) => {
        const { status, data } = await PresupuestosServices.itemGasto.update(id, values)
        showHttpToast(status, { detail: data.message })
        if (status === 200) {
            setIsVisible((prev) => ({ ...prev, modalForm: false, modalConfirmUpdate: false }))
            reloadFilters()
        }
    }
    const handleDelete = async () => {
        if (values.id) {
            const { status, data } = await PresupuestosServices.itemGasto.delete(values.id)
            showHttpToast(status, { detail: data.message })
            hide('modalConfirmDelete')
            if (status === 200) {
                reloadFilters()
            }
        }
    }
    const handleToggleActive = async (values) => {
        if (values.id) {
            const bodyParams = { activo: !values.activo }
            const { status, data } = await PresupuestosServices.itemGasto.update(values.id, bodyParams)
            showHttpToast(status, { detail: data.message })
            if (status === 200) reloadFilters()
        }
    }
    const clearStates = () => {
        setValues(initialValues)
        setErrors({})
        objetosGasto.length && setObjetosGasto([])
    }
    const handleSearchObjetosGasto = async (partida) => {
        if (partida) {
            const { status, data } = await PresupuestosServices.objetoGasto.search(partida)
            if (status === 200) {
                setObjetosGasto(data.data)
            }
        } else {
            setObjetosGasto([])
        }
    }

    return (
        <div className='h-100 p-4'>
            <div className='h-100 bg-white'>
                <div className='d-flex align-items-center bg-blue text-white py-3 px-4'>
                    <h2 className='mb-0 fw-normal'>Items de Gasto</h2>
                    <div className='ms-auto'>
                        <Button
                            startIcon={<span className='bi-plus-lg' />}
                            variant='light'
                            onClick={() => show('modalForm')}
                        >
                            Nuevo Item
                        </Button>
                    </div>
                </div>
                <div className='p-3'>
                    <DataTable
                        values={items}
                        columns={columns}
                        lazy
                        pagination
                        totalItems={pagination.totalRecords}
                        currentPage={pagination.currentPage}
                        pageSize={pagination.pageSize}
                        pageSizeOptions={[10, 25, 50, 100]}
                        onPageChange={handlePage}
                        onPageSizeChange={handlePageSize}
                        onSort={handleSort}
                        filterable
                        onFilter={handleFilter}
                    />
                </div>
            </div>
            <Confirm
                show={isVisible.modalConfirmDelete}
                onHide={() => hide('modalConfirmDelete')}
                onReject={() => hide('modalConfirmDelete')}
                onAccept={handleDelete}
                onExited={clearStates}
            >
                <span className='lh-1' style={{ fontSize: '1.1rem' }}>
                    ¿Esta seguro de <span className='text-primary'>ELIMINAR</span> el Item de Gasto "{values.nombre}
                    "?
                </span>
            </Confirm>
            <Modal show={isVisible.modalForm} onExited={clearStates}>
                <div className='px-3 py-2 border-bottom fs-4 fw-medium'>
                    {values.id ? 'Editar' : 'Registrar'} Item de Gasto
                </div>
                <div className='p-3'>
                    <FieldGroup label='Partida' feedback={errors.objeto_gasto} showFeedback={!!errors.objeto_gasto}>
                        <EditableSelect
                            options={objetosGasto}
                            optionValue='partida'
                            valueAsOption
                            name='objeto_gasto'
                            value={values.objeto_gasto}
                            onChange={handleChangeValues}
                            lazy
                            onSearch={handleSearchObjetosGasto}
                            containerClassName='w-100'
                            className={errors.objeto_gasto ? 'is-invalid' : undefined}
                            menuStyle={{ minWidth: '100%' }}
                            optionTemplate={(objeto) => `${objeto.partida} - ${objeto.descripcion}`}
                            disabled={!!values.id}
                        />
                    </FieldGroup>
                    <FieldGroup label='Clase Gasto'>
                        <Select
                            options={clasesGasto}
                            optionValue='id'
                            optionLabel='nombre'
                            optionTemplate={(clase) => `${clase.codigo} - ${clase.nombre}`}
                            value={values.objeto_gasto?.clase_gasto_id ?? ''}
                            onChange={() => {}}
                            disabled
                            placeholder=' '
                        />
                    </FieldGroup>
                    <FieldGroup label='Detalle' feedback={errors.nombre} showFeedback={!!errors.nombre}>
                        <Input
                            name='nombre'
                            value={values.nombre}
                            onChange={handleChangeValues}
                            isInvalid={!!errors.nombre}
                        />
                    </FieldGroup>
                    <FieldGroup label='Fecha de alta' feedback={errors.fecha_alta} showFeedback={!!errors.fecha_alta}>
                        <Input
                            type='date'
                            name='fecha_alta'
                            value={values.fecha_alta}
                            onChange={handleChangeValues}
                            isInvalid={!!errors.fecha_alta}
                        />
                    </FieldGroup>
                    <FieldGroup label='Fecha de baja' feedback={errors.fecha_baja} showFeedback={!!errors.fecha_baja}>
                        <Input
                            type='date'
                            name='fecha_baja'
                            value={values.fecha_baja}
                            onChange={handleChangeValues}
                            isInvalid={!!errors.fecha_baja}
                        />
                    </FieldGroup>
                </div>
                <div className='px-3 py-2 border-top text-end'>
                    <Button
                        className='me-2'
                        variant='outline-primary'
                        onClick={() => hide('modalForm')}
                        startIcon={<span className='bi-x-lg' />}
                        disabled={submitting}
                    >
                        Cancelar
                    </Button>
                    <Button onClick={handleSubmit} startIcon={<span className='bi-floppy' />} loading={submitting}>
                        Guardar
                    </Button>
                </div>
            </Modal>
        </div>
    )
}
