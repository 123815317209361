import { FieldGroup } from 'modules/modular/FieldGroup'
import { Input } from 'modules/modular/Input'
import { empty } from 'modules/modular/utils'
import { forwardRef, useImperativeHandle, useState } from 'react'
import { formatCurrency } from 'utils'

const initialRecibo = {
    numero_recibo: '',
    preimpreso: '1000000',
    cantidad: '1',
    aclaracion: '',
}

const validateRecibo = (values) => {
    const errors = {}
    if (!(values.cantidad > 0 && values.cantidad <= 10)) errors.cantidad = 'Debe ser mínimo 1 y máximo 10'
    if (!(values.preimpreso > 0)) errors.preimpreso = 'Debe ser mínimo 1'
    return empty(errors) ? null : errors
}

export default function DatosRecibo({ innerRef, inputRefs, totales, ...props }) {
    const [recibo, setRecibo] = useState(initialRecibo)
    const [errors, setErrors] = useState({})

    /** Maneja el cambio de valor de los inputs. */
    const handleChange = (e) => {
        const name = e.target.name
        setRecibo((prev) => ({ ...prev, [name]: e.target.value }))
        errors[name] && setErrors((prev) => ({ ...prev, [name]: '' }))
    }
    /** Maneja el evento keyup de los inputs. */
    const handleKeyUp = (e) => {
        if (e.key === 'Enter') {
            inputRefs?.current['buttonTerminar']?.focus()
        }
    }

    /** Valida los datos del recibo. */
    const validate = () => {
        const _errors = validateRecibo(recibo)
        if (_errors) {
            setErrors(_errors)
        }
        return _errors
    }
    /** Establece los datos del recibo. */
    const set = (data) => {
        setRecibo((prev) => ({ ...prev, ...data }))
    }
    /** Obtiene los datos del recibo. */
    const get = () => {
        return recibo
    }

    return (
        <div {...props}>
            <div className='bg-blue rounded text-white select-none mb-3' style={{ padding: '.75rem 1rem' }}>
                <span className='fw-bold' style={{ fontSize: '1.125rem' }}>
                    RECIBO
                </span>
            </div>
            <div className='px-3'>
                <div className='row g-3'>
                    <FieldGroup
                        label='Nro. Control:'
                        labelClassName='fw-medium'
                        labelStyle={{ width: '6.25rem' }}
                        className='col-3 mb-0'
                        inline
                    >
                        <Input
                            defaultValue={recibo.numero_recibo}
                            isInvalid={errors.numero_recibo}
                            size='sm'
                            disabled
                            className='text-end'
                        />
                    </FieldGroup>
                    <FieldGroup label='Cantidad:' labelClassName='fw-medium' className='col-3 mb-0' inline>
                        <Input
                            type='number'
                            name='cantidad'
                            value={recibo.cantidad}
                            onChange={handleChange}
                            onKeyUp={handleKeyUp}
                            min={1}
                            className='text-end'
                            isInvalid={errors.cantidad}
                            size='sm'
                        />
                    </FieldGroup>
                    <FieldGroup label='Nro. Preimpreso:' labelClassName='fw-medium' className='col-3 mb-0' inline>
                        <Input
                            type='number'
                            name='preimpreso'
                            value={recibo.preimpreso}
                            onChange={handleChange}
                            onKeyUp={handleKeyUp}
                            min={1}
                            className='text-end'
                            isInvalid={errors.preimpreso}
                            size='sm'
                        />
                    </FieldGroup>
                    <FieldGroup label='Importe Total (Bs):' labelClassName='fw-medium' className='col-3 mb-0' inline>
                        <Input
                            value={formatCurrency(totales.cuenta + totales.cuentas_asociadas)}
                            onChange={() => {}}
                            className='text-end'
                            size='sm'
                            disabled
                        />
                    </FieldGroup>
                    <FieldGroup
                        label='Aclaración:'
                        labelClassName='fw-medium'
                        labelStyle={{ width: '6.25rem' }}
                        className='col-12 mb-0'
                        inline
                    >
                        <Input
                            name='aclaracion'
                            value={recibo.aclaracion}
                            onChange={handleChange}
                            onKeyUp={handleKeyUp}
                            size='sm'
                        />
                    </FieldGroup>
                </div>
            </div>
            <DatosReciboRef ref={innerRef} validate={validate} get={get} set={set} />
        </div>
    )
}

const DatosReciboRef = forwardRef(({ validate, get, set }, ref) => {
    useImperativeHandle(ref, () => ({
        validateRecibo() {
            return validate()
        },
        getRecibo() {
            return get()
        },
        setRecibo(data) {
            return set(data)
        },
    }))
    return null
})
