import saveAs from 'file-saver'
import { formatDate } from 'modules/modular/utils'
const Excel = require('exceljs')

export default class RecaudacionesPorCuentaExcel {
    totalReport = 0.0

    /**
     * Metodo que genera el reporte Detalle de Recaudaciones Segun Recibos en formato excel
     *
     * @param JSON details
     * @param string userName
     * @param string initDate
     * @param string endDate
     * @param string fileName nombre que tendra el archivo al descargar
     */
    excelReport(details, userName, initDate, endDate, fileName) {
        //const report = new ExcelFile
        this.totalReport = 0.0

        const date = new Date()
        const outputDate =
            date.getFullYear().toString() +
            String(date.getMonth() + 1).padStart(2, '0') +
            String(date.getDate()).padStart(2, '0')
        const outputTime = `${date.getHours()}${date.getMinutes()}${date.getSeconds()}`

        const workbook = new Excel.Workbook()
        const worksheet = workbook.addWorksheet('Det-Utis', { views: { showGridlines: false } })

        worksheet.columns = [
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15, alignment: 'right', style: { numFmt: '#,##0.00_);(#,##0.00)' } },
            { width: 15 },
        ]

        worksheet.mergeCells(`A1:L1`)
        worksheet.getCell('A1').value = 'Detalle de Recaudaciones por Cuenta'
        worksheet.getCell('A1').alignment = { horizontal: 'center', vertical: 'middle' }
        worksheet.getCell('A1').font = {
            bold: true,
            size: 14,
        }
        worksheet.getRow(1).height = 25

        worksheet.getCell('A2').value = 'Usuario'
        worksheet.getCell('B2').value = userName

        worksheet.getCell('A3').value = 'Fecha'
        worksheet.getCell('B3').value = `Del ${initDate} al ${endDate}`

        var indexCell = 5

        for (var i = 0; i < details.length; i++) {
            this.totalReport += parseFloat(details[i].total)
            worksheet.getCell(`A${indexCell}`).value = 'Cuenta:'
            worksheet.getCell(`A${indexCell}`).font = {
                bold: true,
                size: 12,
            }
            worksheet.getCell(`B${indexCell}`).value = details[i].nombre
            worksheet.getCell(`B${indexCell}`).font = {
                bold: true,
                size: 12,
            }
            worksheet.mergeCells(`B${indexCell}:L${indexCell}`)

            ++indexCell
            //tabla items
            const auxTable = {
                name: `table${i}`,
                ref: `A${indexCell}`,
                headerRow: true,
                totalsRow: true,
                columns: [
                    { name: 'Fecha', },
                    { name: 'Nro. Recibo' },
                    { name: 'Nro. Preimpreso' },
                    { name: 'apellido 1' },
                    { name: 'apellido 2' },
                    { name: 'apellido 3' },
                    { name: 'nombre 1' },
                    { name: 'nombre 2' },
                    { name: 'Documento' },
                    { name: 'Complemento' },
                    { name: 'Total', totalsRowFunction: 'sum' },
                    { name: 'username' },
                ],
                rows: [...this.listItems(details, i)],
            }


            indexCell += details[i].detalle.length + 4

            worksheet.addTable(auxTable)

            worksheet.getCell(`A${indexCell - 3}`).value = 'Total Cuenta'
            worksheet.getCell(`A${indexCell - 3}`).alignment = { horizontal: 'right' }
            worksheet.getCell(`A${indexCell - 3}`).font = {
                bold: true,
            }
            worksheet.getCell(`K${indexCell - 3}`).font = {
                bold: true,
            }

            worksheet.getCell(`A${indexCell - 3}`).border = {
                top: { style: 'medium' },
                bottom: { style: 'medium' },
                left: { style: 'medium' },
            }
            worksheet.mergeCells(`A${indexCell - 3}:J${indexCell - 3}`)
            worksheet.getCell(`K${indexCell - 3}`).border = {
                top: { style: 'medium' },
                bottom: { style: 'medium' },
                right: { style: 'medium' },
            }
        }

        worksheet.getCell(`I${indexCell}`).value = 'Total Reporte'
        worksheet.mergeCells(`I${indexCell}:J${indexCell}`)
        worksheet.getCell(`I${indexCell}`).font = {
            bold: true,
            size: 12,
        }
        worksheet.getCell(`I${indexCell}`).border = {
            top: { style: 'medium' },
            left: { style: 'medium' },
            bottom: { style: 'medium' },
        }
        worksheet.getCell(`I${indexCell}`).alignment = { horizontal: 'right' }

        worksheet.getCell(`K${indexCell}`).value = this.totalReport
        worksheet.getCell(`K${indexCell}`).font = {
            bold: true,
            size: 12,
        }
        worksheet.getCell(`K${indexCell}`).border = {
            top: { style: 'medium' },
            right: { style: 'medium' },
            bottom: { style: 'medium' },
        }
        worksheet.getCell(`K${indexCell}`).alignment = { horizontal: 'right' }

        workbook.xlsx.writeBuffer().then(function (buffer) {
            if (fileName === '') {
                saveAs(
                    new Blob([buffer], { type: 'application/octet-stream' }),
                    `${outputDate}${outputTime}_Report.xlsx`,
                )
            } else {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${fileName}.xlsx`)
            }
        })
    }

    listItems(items, i) {
        const list = items[i].detalle.map((item) => {
            return [
                formatDate(item.fecha_pago, 'd-m-Y'),
                item.recibo,
                item.preimpreso,
                item.persona_apellido_1,
                item.persona_apellido_2,
                item.persona_apellido_3,
                item.persona_nombre_1,
                item.persona_nombre_2,
                item.persona_documento,
                item.persona_complemento,
                parseFloat(item.monto),
                item.user_username,
            ]
        })
        return list
    }
}
