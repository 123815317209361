import { Modal } from 'modules/modular/Modal'
import SelectPersona from './SelectPersona'

/**
 * Modal que permite seleccionar una persona de una lista.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {Array<Object>} props.personas - Lista de personas disponibles.
 * @param {Function} props.onSelectPersona - Función que se ejecuta cuando se confirma la selección de una persona.
 * @param {Function} props.searchPersona - Función para buscar personas por documento o código.
 * @param {string} props.initialSearcherValue - Valor inicial que se mostrara en el input de búsqueda.
 */
export default function ModalSelectPersona({
    personas,
    onSelectPersona,
    searchPersona,
    initialSearcherValue,
    ...props
}) {
    return (
        <Modal style={{ width: '50rem' }} closeOnBackdrop={false} {...props}>
            <div className='px-3 py-2 border-bottom fs-4 fw-medium'>Personas</div>
            <SelectPersona
                personas={personas}
                onSelectPersona={onSelectPersona}
                onCancel={() => props.onHide?.()}
                searchPersona={searchPersona}
                initialSearcherValue={initialSearcherValue}
            />
        </Modal>
    )
}
