import { classNames, joinStyles, throttle } from '../../utils'
import { orders } from '../interfaces'
import ColumnFilterCell from './ColumnFilterCell'
import ArrowDownUp from '../../icons/ArrowDownUp'
import { BaseDataTableCheckbox, BaseTd, BaseTh, BaseThead, BaseTr } from '../../base/BaseDataTable'
import { useCallback } from 'react'
import SortAlphaDown from '../../icons/SortAlphaDown'
import SortAlphaUpAlt from '../../icons/SortAlphaUpAlt'
// Iconos de orden
const sortIcons = {
    [orders.ascOrder]: <SortAlphaDown />,
    [orders.descOrder]: <SortAlphaUpAlt />,
    [`${orders.noOrder}`]: <ArrowDownUp />,
}
export function DataTableHeader({
    headerGroups,
    innerRef,
    columnFilters,
    filters,
    setFilters,
    filterable,
    filterDelay,
    onFilter,
    sort,
    onSort,
    selectionMode,
    allSelected,
    onToggleSelectAll,
    headerElement,
    defaultAlignHeader,
    ...props
}) {
    const handleFilter = useCallback(
        throttle((f) => {
            onFilter && onFilter(f)
        }, filterDelay || 0),
        [filterDelay],
    )
    const handleChangeFilter = (field, value) => {
        const _filters = { ...filters, [field]: value }
        setFilters(_filters)
        handleFilter(_filters)
    }
    /** Handler que se ejecuta cuando se presiona Enter sobre el input de de/seleccionar todas las filas del DataTable. */
    const handleSelectorEnter = ({ key }) => {
        key === 'Enter' && onToggleSelectAll()
    }
    const getHeaderProps = (col) => {
        const field = col.field ?? ''
        const cellClassName = classNames(
            col.className,
            col.headerClassName,
            defaultAlignHeader && `pos-${defaultAlignHeader}`,
        )
        const cellStyle = joinStyles(col.style, col.headerStyle)
        const sorted = field === sort.field
        const sortIcon = field === sort.field ? sortIcons[`${sort.order}`] : sortIcons[`${orders.noOrder}`]
        const sortIconClassName = 'dth-sort-icon'
        const sortElement = col.sortable ? <span className={sortIconClassName}>{sortIcon}</span> : undefined
        const headerElement =
            typeof col.header === 'function' ? (
                col.header({ sortElement, sorted })
            ) : col.sortable ? (
                <div className={classNames('dth-sortable', { sorted })}>
                    {col.header}
                    {sortElement}
                </div>
            ) : (
                col.header
            )
        return {
            cellClassName,
            cellStyle,
            sorted,
            sortIcon,
            sortIconClassName,
            sortElement,
            onSort: col.sortable ? () => onSort(field) : undefined,
            headerElement,
        }
    }
    const selectorElement =
        selectionMode === 'multiple' ? (
            <div className='dth-selector'>
                <BaseDataTableCheckbox
                    checked={allSelected}
                    onChange={onToggleSelectAll}
                    onKeyUp={handleSelectorEnter}
                />
            </div>
        ) : undefined
    const filterRow = filterable ? (
        <BaseTr>
            {columnFilters.map((columnFilter) =>
                columnFilter.selector || !columnFilter.filter ? (
                    <BaseTd key={columnFilter.id} />
                ) : (
                    <ColumnFilterCell
                        key={columnFilter.id}
                        {...columnFilter}
                        value={filters && filters[columnFilter.field] !== undefined ? filters[columnFilter.field] : ''}
                        onChangeFilter={handleChangeFilter}
                    />
                ),
            )}
        </BaseTr>
    ) : undefined
    const tableHeaderElement = (
        <BaseThead ref={innerRef} {...props}>
            {headerGroups.map((headerGroup) => (
                <BaseTr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                        if (header.isPlaceholder) {
                            return null
                        }
                        const col = header.column
                        const cellProps = {
                            ...(header.colSpan > 1 && { colSpan: header.colSpan }),
                            ...(header.rowSpan > 1 && { rowSpan: header.rowSpan }),
                        }
                        if (header.column.selector) {
                            return (
                                <BaseTd key={header.id} {...cellProps}>
                                    {selectorElement}
                                </BaseTd>
                            )
                        }
                        const props = getHeaderProps(col)
                        return (
                            <BaseTh
                                key={header.id}
                                onClick={props.onSort}
                                className={props.cellClassName}
                                style={props.cellStyle}
                                {...cellProps}
                            >
                                {props.headerElement}
                            </BaseTh>
                        )
                    })}
                </BaseTr>
            ))}
            {filterRow}
        </BaseThead>
    )
    const getTableHeaderProps = () => {
        return {
            filterRow,
            selectorElement,
            headerGroups,
            getHeaderProps,
            theadRef: innerRef,
        }
    }
    return typeof headerElement === 'function'
        ? headerElement(getTableHeaderProps())
        : (headerElement ?? tableHeaderElement)
}
