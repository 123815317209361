import { fuenteFondoOptions, initialPagination } from 'data/constants'
import useFilters, { objectToQueryString } from 'hooks/useFilters'
import useHttpToast from 'hooks/useHttpToast'
import { Button } from 'modules/modular/Button'
import { Checkbox } from 'modules/modular/Checkbox'
import { DataTable } from 'modules/modular/DataTable'
import { FieldGroup } from 'modules/modular/FieldGroup'
import { Input } from 'modules/modular/Input'
import { Modal } from 'modules/modular/Modal'
import { Select } from 'modules/modular/Select'
import { useEffect, useMemo, useState } from 'react'
import ContabilidadServices from 'services/contabilidad.service'
import ModalFormUsuario from './components/ModalFormUsuario'
import ModalAssignRoles from './components/ModalAssignRoles'
import { empty } from 'modules/modular/utils'
import { pick } from 'utils'

const initialUsuario = {
    funcionario: '',
    email: '',
    nombres: '',
    apellidos: '',
    ci: '',
    password: '',
    confirmar: '',
    activo: true,
    info: '',
    actividad: '',
    actividad_id: '',
    rol: '',
    beneficiario: true,
    beneficiario_fondo_rotatorio: '',
    beneficiario_fondo_avance: '',
    send_account: true,
    da: [],
}
const initialFondoAvance = {
    monto_asignado: '0.00',
    fuente_fondo: 'ESPECIFICOS',
    numero_devengado: '',
}
const filterOptions = { contain: ['username', 'email', 'apellidos', 'nombres'], bool: ['activo'] }

/** Valida los datos de un Fondo Rotatorio. */
const validateFondoRotatorio = (values) => {
    const _errors = {}
    if (!values.fuente_fondo) _errors.fuente_fondo = 'Requerido'
    if (!values.numero_devengado.trim()) _errors.numero_devengado = 'Requerido'
    if (values.monto_asignado) {
        if (!(values.monto_asignado > 0)) _errors.monto_asignado = 'El monto debe ser mayor a cero'
    } else {
        _errors.monto_asignado = 'Requerido'
    }
    return empty(_errors) ? null : _errors
}
/** Valida los datos de un Fondo en avance. */
const validateFondoAvance = (values) => {
    const _errors = {}
    if (!values.fuente_fondo) _errors.fuente_fondo = 'Requerido'
    if (!values.numero_devengado.trim()) _errors.numero_devengado = 'Requerido'
    if (values.monto_asignado) {
        if (!(values.monto_asignado > 0)) _errors.monto_asignado = 'El monto debe ser mayor a cero'
    } else {
        _errors.monto_asignado = 'Requerido'
    }
    return empty(_errors) ? null : _errors
}
const activoTemplate = ({ value, filterCallback }) => (
    <div className='d-flex justify-content-center'>
        <Checkbox
            value={value}
            onChange={(e) => filterCallback(e.target.value)}
            style={{ fontSize: '1.5rem' }}
            triState
        />
    </div>
)

export default function ListUsuario() {
    const { showHttpToast } = useHttpToast()
    const { filters, handlePage, handlePageSize, handleSort, handleFilter, reloadFilters } = useFilters({
        filterOptions,
    })
    const [usuarios, setUsuarios] = useState([])
    const [pagination, setPagination] = useState(initialPagination)
    const [selected, setSelected] = useState(initialUsuario)
    const [fondoAvance, setFondoAvance] = useState(initialFondoAvance)
    const [errors, setErrors] = useState({})
    const [submitting, setSubmitting] = useState(false)
    const [isVisible, setIsVisible] = useState({
        modalForm: false,
        modalAssign: false,
        modalRotatorio: false,
        modalAvance: false,
        fieldPassword: false,
        fieldConfirmar: false,
    })

    useEffect(() => {
        const loadUsuarios = async () => {
            const params = objectToQueryString(filters)
            const { status, data } = await ContabilidadServices.usuario.list(params)
            if (status === 200) {
                setUsuarios(data.data)
                setPagination(data.pagination)
            } else {
                showHttpToast(status, { detail: data.message })
            }
        }
        loadUsuarios()
    }, [filters])

    const show = (name) => setIsVisible((prev) => ({ ...prev, [name]: true }))
    const hide = (name) => setIsVisible((prev) => ({ ...prev, [name]: false }))

    const handleAction = (action, rowData = null) => {
        if (action === 'create') {
            setSelected(initialUsuario)
            show('modalForm')
        } else if (action === 'update') {
            const rol = rowData.roles.includes('AdministradorContabilidadDA')
                ? 'AdministradorContabilidadDA'
                : rowData.roles.includes('AdministradorPresupuestos')
                ? 'AdministradorPresupuestos'
                : rowData.roles.includes('ReportesPresupuestosDA')
                ? 'ReportesPresupuestosDA'
                : ''
            const roles = {
                beneficiario_fondo_rotatorio: rowData.roles.includes('BeneficiarioFondoRotatorio'),
                beneficiario_fondo_avance: rowData.roles.includes('BeneficiarioFondoAvance'),
            }
            setSelected({
                ...rowData,
                actividad: rowData.codigo_actividad ?? '',
                rol,
                ...roles,
            })
            show('modalForm')
        } else if (action === 'assign') {
            setSelected(rowData)
            show('modalAssign')
        } else if (action === 'rotatorio') {
            setSelected({
                ...rowData,
                fuente_fondo: rowData.fuente_fondo ?? 'ESPECIFICOS',
                numero_devengado: rowData.numero_devengado ?? '',
            })
            show('modalRotatorio')
        } else if (action === 'avance') {
            loadFondoAvance(rowData)
        } else if (action === 'toggleActive') {
            handleToggleActive(rowData)
        }
    }

    const optionsTemplate = ({ data }) => (
        <div className='text-center text-nowrap'>
            <Button
                onClick={() => handleAction('toggleActive', data)}
                variant={data.activo ? 'danger' : 'success'}
                size='sm'
                className='me-2'
                title={data.activo ? 'Inhabilitar' : 'Habilitar'}
            >
                <span className={data.activo ? 'bi-x-circle' : 'bi-check-circle'} />
            </Button>
            <Button onClick={() => handleAction('update', data)} size='sm' className='me-2' title='Editar'>
                <span className='bi-pencil' />
            </Button>
            {/* <Button onClick={() => handleAction('assign', data)} size='sm' className='me-2' title='Editar roles'>
                <span className='bi-person-gear' />
            </Button> */}
            <Button
                onClick={() => handleAction('rotatorio', data)}
                variant='success'
                size='sm'
                className='me-2'
                disabled={!data.actividad_id || !data.roles.includes('BeneficiarioFondoRotatorio')}
                title='Fondo Rotatorio'
            >
                F. Rotatorio
            </Button>
            <Button
                onClick={() => handleAction('avance', data)}
                variant='success'
                size='sm'
                disabled={!data.actividad_id || !data.roles.includes('BeneficiarioFondoAvance')}
                title='Fondo en Avance'
            >
                F. Avance
            </Button>
        </div>
    )
    const columns = useMemo(
        () => [
            { field: 'email', header: 'Correo', sortable: true, filter: true },
            { field: 'apellidos', header: 'Apellidos', sortable: true, filter: true },
            { field: 'nombres', header: 'Nombres', sortable: true, filter: true },
            {
                field: 'actividad',
                header: 'Actividad',
                default: 'NO ASIGNADO',
            },
            {
                field: 'activo',
                header: 'Activo',
                cell: ({ value }) => (value ? 'Si' : 'No'),
                cellClassName: 'text-center',
                sortable: true,
                filter: true,
                filterElement: activoTemplate,
            },
            { field: 'id', header: 'Opciones', cell: optionsTemplate },
        ],
        [],
    )

    const handleChangeUsuario = (e) => {
        const name = e.target.name
        setSelected((prev) => ({ ...prev, [name]: e.target.value }))
        errors[name] && setErrors({ ...errors, [name]: '' })
    }
    const handleChangeFondoAvance = (e) => {
        const name = e.target.name
        setFondoAvance((prev) => ({ ...prev, [name]: e.target.value }))
        errors[name] && setErrors({ ...errors, [name]: '' })
    }
    const updateFondoRotatorio = async () => {
        setSubmitting(true)
        if (selected.id) {
            const _errors = validateFondoRotatorio(selected)
            if (_errors) {
                setErrors(_errors)
            } else {
                const bodyParams = pick(selected, ['fuente_fondo', 'monto_asignado', 'numero_devengado'])
                const { status, data } = await ContabilidadServices.usuario.update(selected.id, bodyParams)
                showHttpToast(status, { detail: data.message })
                if (status === 200) {
                    reloadFilters()
                    hide('modalRotatorio')
                }
            }
        }
        setSubmitting(false)
    }
    const updateFondoAvance = async () => {
        setSubmitting(true)
        if (selected.id) {
            const _errors = validateFondoAvance(fondoAvance)
            if (_errors) {
                setErrors(_errors)
            } else {
                const bodyParams = {
                    ...fondoAvance,
                    beneficiario_id: selected.id,
                    actividad_id: selected.actividad_id,
                }
                const { status, data } = await ContabilidadServices.fondoAvance.update(bodyParams)
                showHttpToast(status, { detail: data.message })
                if (status === 200) {
                    hide('modalAvance')
                }
            }
        }
        setSubmitting(false)
    }
    const handleToggleActive = async (rowData) => {
        if (rowData?.id) {
            const activo = !rowData.activo
            const { status, data } = await ContabilidadServices.usuario.update(rowData.id, { activo })
            showHttpToast(status, {
                detail:
                    status === 200
                        ? activo
                            ? 'Usuario habilitado correctamente'
                            : 'Usuario inhabilitado correctamente'
                        : data.message,
            })
            if (status === 200) {
                reloadFilters()
            }
        }
    }

    const loadFondoAvance = async (usuario) => {
        const { status, data } = await ContabilidadServices.fondoAvance.byUsuario(usuario.id)
        if (status === 200) {
            setSelected(usuario)
            setFondoAvance(data.data)
            show('modalAvance')
        } else {
            showHttpToast(status, { detail: data.message })
        }
    }

    const clearSelected = () => {
        setSelected(initialUsuario)
        setErrors({})
    }

    return (
        <div className='h-100 p-4'>
            <div className='h-100 bg-white'>
                <div className='d-flex align-items-center bg-blue text-white py-3 px-4'>
                    <h2 className='mb-0 fw-normal'>Lista de Usuarios</h2>
                    <div className='ms-auto'>
                        <Button
                            startIcon={<span className='bi-plus-lg' />}
                            onClick={() => handleAction('create')}
                            variant='light'
                        >
                            Nuevo Usuario
                        </Button>
                    </div>
                </div>
                <div className='p-3'>
                    <DataTable
                        columns={columns}
                        values={usuarios}
                        lazy
                        onSort={handleSort}
                        filterable
                        onFilter={handleFilter}
                        pagination
                        currentPage={pagination.currentPage}
                        totalItems={pagination.totalRecords}
                        pageSize={pagination.pageSize}
                        onPageChange={handlePage}
                        onPageSizeChange={handlePageSize}
                        defaultAlignHeader='center'
                    />
                </div>
            </div>
            <ModalFormUsuario
                show={isVisible.modalForm}
                onHide={() => hide('modalForm')}
                onExited={clearSelected}
                id={selected.id}
                onSuccessCreate={() => {
                    reloadFilters()
                    hide('modalForm')
                }}
                onSuccessUpdate={() => {
                    hide('modalForm')
                    reloadFilters()
                }}
            />
            <Modal
                show={isVisible.modalRotatorio}
                onHide={() => hide('modalRotatorio')}
                onExited={clearSelected}
                style={{ width: '25rem' }}
                closeOnBackdrop={false}
                keyboard={false}
            >
                <div className='px-3 py-2 border-bottom fs-4 fw-medium'>Fondo Rotatorio</div>
                <div className='p-3'>
                    <div className='mb-3'>
                        <span className='fw-medium me-2'>Usuario:</span>
                        {selected.email}
                    </div>
                    <FieldGroup label='Fondos' feedback={errors.fuente_fondo} showFeedback={!!errors.fuente_fondo}>
                        <Select
                            options={fuenteFondoOptions}
                            name='fuente_fondo'
                            value={selected.fuente_fondo}
                            onChange={handleChangeUsuario}
                            isInvalid={!!errors.fuente_fondo}
                            placeholder={null}
                        />
                    </FieldGroup>
                    <FieldGroup
                        label='Monto Asignado'
                        feedback={errors.monto_asignado}
                        showFeedback={!!errors.monto_asignado}
                    >
                        <Input
                            name='monto_asignado'
                            type='currency'
                            value={selected.monto_asignado}
                            onChange={handleChangeUsuario}
                            isInvalid={errors.monto_asignado}
                        />
                    </FieldGroup>
                    <FieldGroup
                        label='Número Devengado'
                        feedback={errors.numero_devengado}
                        showFeedback={!!errors.numero_devengado}
                    >
                        <Input
                            name='numero_devengado'
                            value={selected.numero_devengado}
                            onChange={handleChangeUsuario}
                            isInvalid={!!errors.numero_devengado}
                        />
                    </FieldGroup>
                </div>
                <div className='px-3 py-2 border-top text-end'>
                    <Button
                        variant='outline-primary'
                        className='me-2'
                        startIcon={<span className='bi-x-lg' />}
                        onClick={() => hide('modalRotatorio')}
                        disabled={submitting}
                    >
                        Cancelar
                    </Button>
                    <Button
                        startIcon={<span className='bi-floppy' />}
                        onClick={updateFondoRotatorio}
                        loading={submitting}
                    >
                        Guardar
                    </Button>
                </div>
            </Modal>
            <Modal
                show={isVisible.modalAvance}
                onHide={() => hide('modalAvance')}
                onExited={clearSelected}
                style={{ width: '25rem' }}
                closeOnBackdrop={false}
                keyboard={false}
            >
                <div className='px-3 py-2 border-bottom fs-4 fw-medium'>Fondo en Avance</div>
                <div className='p-3'>
                    <div className='mb-2'>
                        <span className='fw-medium me-2'>Usuario:</span>
                        {selected.email}
                        <br />
                        <span className='fw-medium me-2'>Fondo en Avance asignado:</span>
                        {fondoAvance.id ? 'Si' : 'No'}
                    </div>
                    {fondoAvance.id && (
                        <div className='mb-2 text-primary fst-italic' style={{ lineHeight: '1.25' }}>
                            <span className='bi-info-circle-fill' /> Modificar los datos actualizará el Fondo en Avance
                            asignado.
                        </div>
                    )}
                    <FieldGroup label='Fondos' feedback={errors.fuente_fondo} showFeedback={!!errors.fuente_fondo}>
                        <Select
                            options={fuenteFondoOptions}
                            name='fuente_fondo'
                            value={fondoAvance.fuente_fondo}
                            onChange={handleChangeFondoAvance}
                            isInvalid={!!errors.fuente_fondo}
                            placeholder={null}
                        />
                    </FieldGroup>
                    <FieldGroup
                        label='Monto Asignado'
                        feedback={errors.monto_asignado}
                        showFeedback={!!errors.monto_asignado}
                    >
                        <Input
                            name='monto_asignado'
                            type='currency'
                            value={fondoAvance.monto_asignado}
                            onChange={handleChangeFondoAvance}
                            isInvalid={errors.monto_asignado}
                        />
                    </FieldGroup>
                    <FieldGroup
                        label='Número Devengado'
                        feedback={errors.numero_devengado}
                        showFeedback={!!errors.numero_devengado}
                    >
                        <Input
                            name='numero_devengado'
                            value={fondoAvance.numero_devengado}
                            onChange={handleChangeFondoAvance}
                            isInvalid={!!errors.numero_devengado}
                        />
                    </FieldGroup>
                </div>
                <div className='px-3 py-2 border-top text-end'>
                    <Button
                        variant='outline-primary'
                        className='me-2'
                        startIcon={<span className='bi-x-lg' />}
                        onClick={() => hide('modalAvance')}
                        disabled={submitting}
                    >
                        Cancelar
                    </Button>
                    <Button startIcon={<span className='bi-floppy' />} onClick={updateFondoAvance} loading={submitting}>
                        {fondoAvance.id ? 'Guardar' : 'Asignar'}
                    </Button>
                </div>
            </Modal>
            <ModalAssignRoles
                userId={selected.id}
                show={isVisible.modalAssign}
                onHide={() => hide('modalAssign')}
                onCloseModal={clearSelected}
                onSuccess={reloadFilters}
            />
        </div>
    )
}
