import { logout, setUser } from 'actions/auth'
import useHttpToast from 'hooks/useHttpToast'
import { Button } from 'modules/modular/Button'
import { FieldGroup } from 'modules/modular/FieldGroup'
import { Input } from 'modules/modular/Input'
import { Modal } from 'modules/modular/Modal'
import { empty } from 'modules/modular/utils'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AuthServices from 'services/auth.service'

const pwdRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).+$/
/** Valida los datos de un usuario para creación. */
const validatePassword = (values) => {
    const _errors = {}
    if (!values.password) _errors.password = 'Requerido'
    if (!values.confirmar) _errors.confirmar = 'Requerido'
    if (values.password && values.confirmar) {
        if (values.password === values.confirmar) {
            if (!pwdRegex.test(values.password)) {
                _errors.password = 'Formato incorrecto'
                _errors.confirmar = 'Formato incorrecto'
            }
        } else {
            _errors.confirmar = 'Las contraseñas deben coincidir'
        }
    }
    return empty(_errors) ? null : _errors
}

export default function ChangeDefaultPassword() {
    const dispatch = useDispatch()
    const { showHttpToast } = useHttpToast()
    const { user, roles } = useSelector((state) => state.auth)
    const [values, setValues] = useState({ password: '', confirmar: '' })
    const [errors, setErrors] = useState({})
    const [isVisible, setIsVisible] = useState({
        fieldPassword: false,
        fieldConfirmar: false,
        modalConfirmRedirect: false,
    })

    const show = (name) => setIsVisible((prev) => ({ ...prev, [name]: true }))
    const toggleVisibility = (name) => setIsVisible((prev) => ({ ...prev, [name]: !prev[name] }))

    const handleChange = (e) => {
        setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        const _errors = validatePassword(values)
        if (_errors) {
            setErrors(_errors)
        } else {
            const { status, data } = await AuthServices.changeDefaultPassword({ password: values.password })
            showHttpToast(status, { detail: data.message })
            if (status === 200) {
                show('modalConfirmRedirect')
            }
        }
    }

    const handleUpdateSesion = () => dispatch(setUser({ user: { ...user, default_password: false }, roles }))
    const handleLogout = () => dispatch(logout())

    return (
        <div className='d-flex h-100 p-4'>
            <div className='container p-0 m-auto bg-white'>
                <div className='d-flex align-items-center bg-blue text-white py-3 px-4'>
                    <h2 className='mb-0 fw-normal'>Cambiar contraseña</h2>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className='p-3'>
                        <p className='fw-medium fs-4'>¡Tu contraseña predeterminada debe ser actualizada!</p>
                        <p>
                            Para poder acceder a tu cuenta, primero debes cambiar la contraseña predeterminada. Por
                            favor, crea una nueva contraseña segura para continuar.
                        </p>
                        <FieldGroup label='Contraseña' feedback={errors.password} showFeedback={!!errors.password}>
                            <div className='position-relative'>
                                <Input
                                    name='password'
                                    type={isVisible.fieldPassword ? 'text' : 'password'}
                                    value={values.password}
                                    onChange={handleChange}
                                    isInvalid={errors.password}
                                    className='bg-img-none'
                                    style={{ paddingRight: '2.5rem' }}
                                />
                                <div
                                    className='position-absolute cursor-pointer'
                                    style={{
                                        fontSize: '1.25rem',
                                        lineHeight: '1.5rem',
                                        right: '.75rem',
                                        top: '.375rem',
                                    }}
                                    onClick={() => toggleVisibility('fieldPassword')}
                                >
                                    <span className={isVisible.fieldPassword ? 'bi-eye-slash' : 'bi-eye'} />
                                </div>
                            </div>
                        </FieldGroup>
                        <FieldGroup
                            label='Confirmar Contraseña'
                            feedback={errors.confirmar}
                            showFeedback={!!errors.confirmar}
                        >
                            <div className='position-relative'>
                                <Input
                                    name='confirmar'
                                    type={isVisible.fieldConfirmar ? 'text' : 'password'}
                                    value={values.confirmar}
                                    onChange={handleChange}
                                    isInvalid={errors.confirmar}
                                    className='bg-img-none'
                                    style={{ paddingRight: '2.5rem' }}
                                />
                                <div
                                    className='position-absolute cursor-pointer'
                                    style={{
                                        fontSize: '1.25rem',
                                        lineHeight: '1.5rem',
                                        right: '.75rem',
                                        top: '.375rem',
                                    }}
                                    onClick={() => toggleVisibility('fieldConfirmar')}
                                >
                                    <span className={isVisible.fieldConfirmar ? 'bi-eye-slash' : 'bi-eye'} />
                                </div>
                            </div>
                        </FieldGroup>
                        <div className='text-primary fst-italic mb-3'>
                            <span className='bi-info-circle-fill' /> La contraseña debe contener al menos una mayúscula,
                            una minúscula, un número y un carácter especial.
                        </div>
                    </div>
                    <div className='bg-blue py-3 text-center'>
                        <Button type='submit' startIcon={<span className='bi-floppy' />}>
                            Guardar
                        </Button>
                    </div>
                </form>
            </div>
            <Modal show={isVisible.modalConfirmRedirect} closeOnBackdrop={false} keyboard={false}>
                <div className='px-3 py-2 border-bottom fs-4 fw-medium'>Confirmar</div>
                <div className='d-flex align-items-center gap-3 p-3'>
                    <div className='d-flex align-items-center'>
                        <span className='bi-question-circle-fill text-primary lh-1' style={{ fontSize: '2.5rem' }} />
                    </div>
                    <div>
                        <span className='lh-1' style={{ fontSize: '1.1rem' }}>
                            ¿Desea <span className='text-primary'>VOLVER A INICIAR SESION</span> para verificar el
                            cambio de contraseña?
                        </span>
                    </div>
                </div>
                <div className='px-3 py-2 border-top text-end'>
                    <Button
                        variant='outline-primary'
                        className='me-2'
                        startIcon={<span className='bi-person-fill' />}
                        onClick={handleUpdateSesion}
                    >
                        Mantener sesión
                    </Button>
                    <Button startIcon={<span className='bi-arrow-bar-right' />} onClick={handleLogout}>
                        Cerrar sesión
                    </Button>
                </div>
            </Modal>
        </div>
    )
}
