import { Button } from 'modules/modular/Button'
import { DataTable } from 'modules/modular/DataTable'
import { Confirm } from 'modules/modular/Modal'
import { formatCurrency } from 'modules/modular/utils'
import { useMemo, useState } from 'react'

export default function LastVenta({ movimientos }) {
    const [selected, setSelected] = useState(null)
    const [isVisible, setIsVisible] = useState({ modalConfirmCancel: false })

    const show = (name) => setIsVisible((prev) => ({ ...prev, [name]: true }))
    const hide = (name) => setIsVisible((prev) => ({ ...prev, [name]: false }))

    const handleAction = (action, rowData = null) => {
        setSelected(rowData)
        if (action === 'cancel') {
            show('modalConfirmCancel')
        }
    }

    const optionsCellTemplate = ({ data }) => (
        <div className='text-center text-nowrap'>
            <Button
                variant='danger'
                size='sm'
                startIcon={<span className='bi-x-circle' />}
                onClick={() => handleAction('cancel', data)}
            >
                Anular
            </Button>
            <Button variant='success' size='sm' startIcon={<span className='bi-printer' />} className='ms-2'>
                Reimprimir
            </Button>
        </div>
    )

    const columns = useMemo(() => [
        {
            field: 'recibo',
            header: 'Nro. Control',
            headerClassName: 'bg-blue text-white',
            cellClassName: 'text-center',
            style: { width: '10rem' },
        },
        {
            field: 'preimpreso',
            header: 'Nro. Preimpreso',
            headerClassName: 'bg-blue text-white',
            cellClassName: 'text-center',
            style: { width: '10rem' },
        },
        { field: 'persona', header: 'Persona', headerClassName: 'bg-blue text-white' },
        {
            field: 'monto',
            header: 'Total (Bs)',
            headerClassName: 'bg-blue text-white',
            cell: ({ value }) => formatCurrency(value),
            cellClassName: 'text-end',
            style: { width: '10rem' },
        },
        {
            id: 'options',
            header: 'Opciones',
            headerClassName: 'bg-blue text-white',
            cell: optionsCellTemplate,
            style: { width: '15rem' },
        },
    ])

    return (
        <>
            <div className='fw-medium fs-5'>ULTIMA VENTA</div>
            <div className='container shadow-sm p-0 bg-body rounded overflow-hidden'>
                <DataTable
                    values={movimientos}
                    columns={columns}
                    defaultAlignHeader='center'
                    emptyMessage={<div className='fw-medium text-center'>Sin ventas realizadas el día de hoy</div>}
                />
                <Confirm
                    show={isVisible.modalConfirmCancel}
                    onReject={() => hide('modalConfirmCancel')}
                >
                    <span className='lh-1' style={{ fontSize: '1.1rem' }}>
                        Esta seguro de <span className='text-primary'>ANULAR</span> el recibo de: "{selected?.persona}"
                    </span>
                </Confirm>
            </div>
        </>
    )
}
