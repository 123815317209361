import ExcelFileAnulados from './ExcelFileAnulados'
export default class ExcelAnulados {
    totalReport = 0.00
    list = []
    listSum = []

    excelReport(details, userName, initDate, endDate, fileName) {
        const report = new ExcelFileAnulados()
        this.totalReport = 0.00
        this.listItems(details)
        const columnWidth = [
            { width: 40, alignmen: 'left' },
            { width: 40 },
            { width: 50 },
            { width: 20 },
            { width: 20 },
            { width: 20, alignment: 'right', style: { numFmt: "#,##0.00_);(#,##0.00)" } }
        ]
        const columnHeader = [
            { name: "Fecha" },
            { name: "Usuario" },
            { name: "Descripcion" },
            { name: "NoRecibo" },
            { name: "Preimpreso" },
            { name: "Total", totalsRowFunction: 'sum' },
        ]
        var totalReporteAnulados = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(this.totalReport)
        report.generateExcelFile(
            details,
            "Rec_Anulados",
            "Recibos Anulados",
            userName,
            initDate,
            endDate,
            columnWidth,
            "F1",
            columnHeader,
            this.list,
            totalReporteAnulados,
            ` Movimientos del reporte`,
            true,
            'C',
            'F',
            this.listSum,
            fileName
        )
    }

    listItems(items) {
        this.totalReport =0;
        this.list = [];
        this.listSum = []
        var rubroTotal = 0;
        
        items.forEach((itemA) => {
            const cuenta = Array.isArray(itemA.cuenta) ? itemA.cuenta : [];
            cuenta.forEach((itemCuenta) => {
                var list2 = [];
                itemCuenta.recibo.forEach((itemRecibo) => {
                    list2.push([
                        itemRecibo.fecha,
                        itemRecibo.usuario,
                        itemRecibo.descripcion,
                        itemRecibo.noRecibo,
                        itemRecibo.preImpreso,
                        itemRecibo.total
                    ])
                    this.totalReport = parseInt(itemRecibo.total)+this.totalReport
                    rubroTotal += parseFloat(itemRecibo.total)
                });
                this.list.push(list2)
            });
            var totalCuentaRubro = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(rubroTotal)
            this.listSum.push(totalCuentaRubro  )
            rubroTotal = 0;
        });
    }
}