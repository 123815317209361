/**
 * Formatea un valor a formato de moneda con 2 decimales.
 * @param value Número.
 * @param config Configuración del formateo.
 * @param config.defaultValue Valor predeterminado devuelto si el número no se puede formatear.
 * @param config.decimalSeparator Separador de decimales.
 * @param config.thousandsSeparator Separador de miles.
 */
export function formatCurrency(value, config) {
    const { decimalSeparator, thousandsSeparator } = config ?? {}
    const defaultValue = config?.defaultValue ?? ''
    value = typeof value === 'string' ? parseFloat(value) : value
    if (isNaN(value)) {
        return defaultValue
    } else {
        const locale =
            decimalSeparator === ',' || (decimalSeparator === undefined && thousandsSeparator === '.')
                ? 'de-DE'
                : 'en-US'
        const useGrouping = thousandsSeparator === '' ? false : true
        const options = { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping }
        const formattedNumber = new Intl.NumberFormat(locale, options).format(value)
        return formattedNumber
    }
}
