import useHttpToast from 'hooks/useHttpToast'
import { FieldGroup } from 'modules/modular/FieldGroup'
import { Input } from 'modules/modular/Input'
import { cleanObject, debounce } from 'modules/modular/utils'
import { useCallback, useEffect, useState } from 'react'
import { formatCurrency, pick } from 'utils'
import ModalSelectCuenta from './components/ModalSelectCuenta'
import { DatosCuentaRef } from './components/DatosCuentaRef'
import { validateCuenta } from './helpers/validateCuenta'
import ListCuentasAsociadas from './components/ListCuentasAsociadas'
import RecaudacionesServices from 'services/recaudaciones.service'

const initialCuenta = {
    codigo: '',
    nombre: '',
    unidad: '',
    precio: '0.00',
    cantidad: '1',
}

export default function DatosCuenta({ innerRef, inputRefs, saveRef, updateTotales, ...props }) {
    const { showHttpToast } = useHttpToast()
    const [cuenta, setCuenta] = useState(initialCuenta)
    const [cuentasAsociadas, setCuentasAsociadas] = useState([])
    const [errors, setErrors] = useState({})
    const [cuentas, setCuentas] = useState([])
    const [isVisible, setIsVisible] = useState({ modalSelectCuenta: false, listCuentasAsociadas: false })

    useEffect(() => {
        updateTotales?.((prev) => ({ ...prev, cuenta: cuenta.precio * cuenta.cantidad }))
    }, [cuenta.precio, cuenta.cantidad])
    useEffect(() => {
        updateTotales?.((prev) => ({
            ...prev,
            cuentas_asociadas: cuentasAsociadas.reduce((carry, c) => {
                return carry + c.precio * c.cantidad
            }, 0),
        }))
    }, [cuentasAsociadas])

    const show = (name) => setIsVisible((prev) => ({ ...prev, [name]: true }))
    const hide = (name) => setIsVisible((prev) => ({ ...prev, [name]: false }))

    /** Almacena los datos de la cuenta seleccionada. */
    const handleSelectCuenta = (cuenta) => {
        setCuenta({
            ...initialCuenta,
            ...cleanObject(pick(cuenta, ['id', 'codigo', 'nombre', 'unidad', 'precio', 'tipo_monto'])),
        })
        setErrors({})
        searchCuentasAsociadas(cuenta.id)
        setTimeout(() => {
            if (cuenta.tipo_monto === 'V') {
                inputRefs?.current['cuenta.precio']?.focus()
            } else {
                inputRefs?.current['buttonTerminar']?.focus({ focusVisible: true })
            }
        }, 250)
    }
    /**
     * Busca las cuentas asociadas de una cuenta.
     * @param {number} id ID de la cuenta principal.
     */
    const searchCuentasAsociadas = async (id) => {
        const { status, data } = await RecaudacionesServices.ventaValores.listCuentasAsociadas(id)
        if (status === 200) {
            setCuentasAsociadas(data.data)
            show('listCuentasAsociadas')
        } else {
            showHttpToast(status, { detail: data.message })
        }
    }
    /** Busca la cuenta por codigo o nombre. */
    const searchCuenta = useCallback(
        debounce(async (params) => {
            const _params = cleanObject(params)
            const { status, data } = await RecaudacionesServices.ventaValores.listCuentas(_params)
            if (status === 200) {
                if (isVisible.modalSelectCuenta) {
                    setCuentas(data.data)
                } else {
                    if (data.data.length === 1) {
                        handleSelectCuenta(data.data[0])
                    } else {
                        setCuentas(data.data)
                        show('modalSelectCuenta')
                    }
                }
            } else {
                showHttpToast(status, { detail: data.message })
            }
        }, 300),
        [isVisible.modalSelectCuenta],
    )

    /** Maneja el cambio de valor de los inputs. */
    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value.toUpperCase()
        if (name === 'codigo' && cuenta.id) {
            setCuenta({ ...initialCuenta, [name]: value })
            cuentasAsociadas.length && setCuentasAsociadas([])
            isVisible.listCuentasAsociadas && hide('listCuentasAsociadas')
            setErrors({})
        } else {
            setCuenta((prev) => ({ ...prev, [name]: value }))
            errors[name] && setErrors((prev) => ({ ...prev, [name]: '' }))
        }
    }
    /** Maneja el evento keyup de los inputs. */
    const handleKeyUp = (e) => {
        if (e.key === 'Enter') {
            const { name } = e.target
            if (name === 'codigo') {
                searchCuenta({ codigo: cuenta.codigo })
            } else if (['precio', 'cantidad'].includes(name)) {
                if (cuenta[name] > 0) {
                    inputRefs?.current['buttonTerminar']?.focus({ focusVisible: true })
                }
            }
        }
    }
    /** Maneja el evento keydown de los inputs. */
    const handleKeyDown = (e) => {
        if (e.key === ' ' || e.key === 'Spacebar') {
            if (e.target.name === 'codigo') {
                e.preventDefault()
            }
        }
    }

    /** Valida los datos de la cuenta. */
    const validate = () => {
        const _errors = validateCuenta(cuenta)
        if (_errors) {
            setErrors(_errors)
        }
        return _errors
    }
    /** Restablece los datos de la cuenta a sus valores iniciales. */
    const reset = () => {
        setCuenta(initialCuenta)
        cuentasAsociadas.length && setCuentasAsociadas([])
    }
    /** Obtiene los datos de la cuenta. */
    const get = () => {
        return { ...cuenta, cuentas_asociadas: cuentasAsociadas }
    }

    return (
        <div {...props}>
            <div className='mb-3'>
                <div className='bg-blue rounded text-white select-none mb-3' style={{ padding: '.75rem 1rem' }}>
                    <span className='fw-bold' style={{ fontSize: '1.125rem' }}>
                        CUENTA
                    </span>
                </div>
                <div className='px-3'>
                    <div className='row g-3'>
                        <FieldGroup
                            label='Código'
                            labelClassName='fw-medium'
                            className='mb-0'
                            style={{ width: '10rem' }}
                        >
                            <Input
                                ref={(el) => saveRef?.(el, 'cuenta.codigo')}
                                name='codigo'
                                value={cuenta.codigo}
                                onChange={handleChange}
                                onKeyUp={handleKeyUp}
                                onKeyDown={handleKeyDown}
                                isInvalid={errors.codigo}
                                size='sm'
                            />
                        </FieldGroup>
                        <FieldGroup label='Nombre' labelClassName='fw-medium' className='col mb-0'>
                            <Input defaultValue={cuenta.nombre} isInvalid={errors.nombre} size='sm' disabled />
                        </FieldGroup>
                        <FieldGroup label='Unidad' labelClassName='fw-medium' className='col-2 mb-0'>
                            <Input defaultValue={cuenta.unidad} isInvalid={errors.unidad} size='sm' disabled />
                        </FieldGroup>
                        <FieldGroup
                            label='Cantidad'
                            labelClassName='fw-medium'
                            className='mb-0'
                            style={{ width: '6rem' }}
                        >
                            <Input
                                type='number'
                                name='cantidad'
                                value={cuenta.cantidad}
                                onChange={handleChange}
                                onKeyUp={handleKeyUp}
                                min={1}
                                className='text-end'
                                isInvalid={errors.cantidad}
                                size='sm'
                            />
                        </FieldGroup>
                        <FieldGroup
                            label='Precio Unit. (Bs)'
                            labelClassName='fw-medium'
                            className='mb-0'
                            style={{ width: '9rem' }}
                        >
                            <Input
                                ref={(el) => saveRef?.(el, 'cuenta.precio')}
                                type='currency'
                                name='precio'
                                value={cuenta.precio}
                                onChange={handleChange}
                                onKeyUp={handleKeyUp}
                                onFocus={(e) => e.target.select()}
                                className='text-end'
                                isInvalid={errors.precio}
                                size='sm'
                                disabled={cuenta.tipo_monto === 'F'}
                            />
                        </FieldGroup>
                        <FieldGroup
                            label='Importe (Bs)'
                            labelClassName='fw-medium'
                            className='mb-0'
                            style={{ width: '10rem' }}
                        >
                            <Input
                                name='total'
                                value={formatCurrency(cuenta.precio * cuenta.cantidad)}
                                onChange={() => {}}
                                isInvalid={errors.total}
                                className='text-end'
                                size='sm'
                                disabled
                            />
                        </FieldGroup>
                    </div>
                </div>
            </div>
            <ListCuentasAsociadas cuentas={cuentasAsociadas} />
            <ModalSelectCuenta
                show={isVisible.modalSelectCuenta}
                onHide={() => hide('modalSelectCuenta')}
                onExited={() => cuentas.length && setCuentas([])}
                cuentas={cuentas}
                onSelectCuenta={(selectedCuenta) => {
                    handleSelectCuenta(selectedCuenta)
                    hide('modalSelectCuenta')
                }}
                searchCuenta={searchCuenta}
                initialSearcherValue={cuenta.codigo}
            />
            <DatosCuentaRef ref={innerRef} validate={validate} reset={reset} get={get} />
        </div>
    )
}
