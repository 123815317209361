import { cleanObject, formatDate, pick } from 'modules/modular/utils'
import ReportByAccountUnitDiezmo from 'Reports/ReportByAccountUnitDiezmo'
import ReportConCuentaUnid from 'Reports/ReportConCuentaUnid'
import RecaudacionesServices from 'services/recaudaciones.service'
import useHttpToast from './useHttpToast'
import ExcelConCuentaUnidad from 'ReportsExcel/ExcelConCuentaUnidad'
import ExcelReportDiezmoAccountUnit from 'ReportsExcel/ExcelReportDiezmoAccountUnit'
import ReportConsolidadoConcepto from 'Reports/ReportConsolidadoConcepto'
import ExcelConcepto from 'ReportsExcel/ExcelConcepto'
import ReportConsolidadoUnidad from 'Reports/ReportConsolidadoUnidad'
import ExcelUnidad from 'ReportsExcel/ExcelUnidad'
import ReportConsolidadoInstitucional from 'Reports/ReportConsolidadoInstitucional'
import ReportCobroComisionBanco from 'Reports/ReportCobroComisionBanco'
import ReportDistribucionAportes from 'Reports/ReportDistribucionAportes'
import ReportRecaudacionRecibos from 'Reports/ReportRecaudacionesRecibos'
import ExcelRecRecibos from 'ReportsExcel/ExcelRecRecibos'
import RecaudacionesPorCuentaPDF from 'Reports/recaudaciones/RecaudacionesPorCuentaPDF'
import RecaudacionesPorCuentaExcel from 'ReportsExcel/recaudaciones/RecaudacionesPorCuentaExcel'
import ReportTiposEstudiante from 'Reports/ReportTiposEstudiante'
import ExcelTiposEstudiante from 'ReportsExcel/ExcelTiposEstudiante'
import ReportAnulados from 'Reports/ReportAnulados'
import ExcelAnulados from 'ReportsExcel/ExcelAnulados'
import KardexValores from 'Reports/KardexValores'
import ExcelValores from 'ReportsExcel/ExcelValores'
import ReportResumenRecibos from 'Reports/ReportResumenRecibos'

export default function useReporteValorados() {
    const { showHttpToast } = useHttpToast()

    const getParams = (filters) => {
        return cleanObject({
            idUser: filters.usuario_id,
            initDate: filters.fecha_inicio.split('-').reverse().join('-'),
            endDate: filters.fecha_fin.split('-').reverse().join('-'),
            idRubro: filters.rubro_id,
            idUnidad: filters.unidad_id,
            idConcepto: filters.concepto_id,
            idCuenta: filters.cuenta_id,
            idEstructura: filters.estructura_id,
            electronico: filters.electronico,
            caja: filters.caja,
        })
    }
    const getOptions = (options = {}) => {
        const type = options?.type ?? 'pdf'
        const reportParams = options?.reportParams ?? []
        const diezmo = options?.diezmo ?? false
        const fileName = options?.fileName ?? `${formatDate(new Date(), 'YmdHis')}_Report`
        return { type, reportParams, diezmo, fileName }
    }

    const consolidadoPorUnidadCuenta = async (filters, options = {}) => {
        const { type, reportParams, diezmo, fileName } = getOptions(options)
        const params = getParams(filters)

        const { status, data } = await RecaudacionesServices.reporteValorados.consolidado.porUnidadCuenta(params)
        if (status === 200) {
            if (type === 'excel') {
                const report = diezmo ? new ExcelReportDiezmoAccountUnit() : new ExcelConCuentaUnidad()
                if (diezmo) {
                    report.generateExcelFile(data.data, ...reportParams, fileName)
                } else {
                    report.excelReport(data.data, ...reportParams, fileName)
                }
            } else {
                const _reportParams = [
                    reportParams.filters.usuario,
                    reportParams.filters.fechaInicio,
                    reportParams.filters.fechaFin,
                    reportParams.usuario,
                    reportParams.modePrint,
                    reportParams.showAdvancedFilters,
                    reportParams.filters,
                    reportParams.isAdmin,
                    reportParams.unidad,
                    reportParams.filters.electronico,
                    reportParams.filters.caja,
                ]
                const report = diezmo ? new ReportByAccountUnitDiezmo() : new ReportConCuentaUnid()
                report.generateReport(data.data, ..._reportParams)
            }
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al generar el reporte' })
        }
    }
    const consolidadoPorConcepto = async (filters, options = {}) => {
        const { type, reportParams, fileName } = getOptions(options)
        const params = getParams(filters)

        const { status, data } = await RecaudacionesServices.reporteValorados.consolidado.porConcepto(params)
        if (status === 200) {
            if (type === 'excel') {
                const report = new ExcelConcepto()
                report.excelReport(data.data, ...reportParams, fileName)
            } else {
                const _reportParams = [
                    reportParams.filters.usuario,
                    reportParams.usuario,
                    reportParams.filters.fechaInicio,
                    reportParams.filters.fechaFin,
                    reportParams.modePrint,
                    reportParams.showAdvancedFilters,
                    reportParams.filters,
                    reportParams.isAdmin,
                    reportParams.unidad,
                    reportParams.filters.electronico,
                    reportParams.filters.caja,
                ]
                const report = new ReportConsolidadoConcepto()
                report.generateReport(data.data, ..._reportParams)
            }
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al generar el reporte' })
        }
    }
    const consolidadoPorUnidad = async (filters, options = {}) => {
        const { type, reportParams, fileName } = getOptions(options)
        const params = cleanObject(filters)

        const { status, data } = await RecaudacionesServices.reporteValorados.consolidado.porUnidad(params)
        if (status === 200) {
            if (type === 'excel') {
                const report = new ExcelUnidad()
                report.excelReport(data.data, ...reportParams, fileName)
            } else {
                const _reportParams = [
                    reportParams.filters.usuario,
                    reportParams.usuario,
                    reportParams.filters.fechaInicio,
                    reportParams.filters.fechaFin,
                    reportParams.modePrint,
                    reportParams.showAdvancedFilters,
                    reportParams.filters,
                    reportParams.isAdmin,
                    reportParams.unidad,
                    reportParams.filters.electronico,
                    reportParams.filters.caja,
                ]
                const report = new ReportConsolidadoUnidad()
                report.generateReport(data.data, ..._reportParams)
            }
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al generar el reporte' })
        }
    }
    const consolidadoInstitucional = async (filters, options = {}) => {
        const { reportParams } = getOptions(options)
        const params = pick(filters, ['fecha_inicio', 'fecha_fin', 'electronico', 'caja'])
        const { status, data } = await RecaudacionesServices.reporteValorados.consolidado.institucional(params)
        if (status === 200) {
            const _reportParams = [
                'Todos',
                reportParams.usuario,
                reportParams.filters.fechaInicio,
                reportParams.filters.fechaFin,
                reportParams.modePrint,
                reportParams.unidad,
                reportParams.filters.electronico,
                reportParams.filters.caja,
            ]
            const report = new ReportConsolidadoInstitucional()
            report.generateReport(data.data, ..._reportParams)
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al generar el reporte' })
        }
    }

    const recaudacionesSegunRecibos = async (filters, options = {}) => {
        const { type, reportParams, fileName } = getOptions(options)
        const params = cleanObject(filters)

        const { status, data } = await RecaudacionesServices.reporteValorados.recaudaciones.segunRecibo(params)
        if (status === 200) {
            if (type === 'excel') {
                const report = new ExcelRecRecibos()
                report.excelReport(data.data, ...reportParams, fileName)
            } else {
                const _reportParams = [
                    reportParams.filters.usuario,
                    reportParams.usuario,
                    reportParams.filters.fechaInicio,
                    reportParams.filters.fechaFin,
                    reportParams.modePrint,
                    reportParams.showAdvancedFilters,
                    reportParams.filters,
                    reportParams.isAdmin,
                    reportParams.unidad,
                    reportParams.filters.electronico,
                    reportParams.filters.caja,
                ]
                const report = new ReportRecaudacionRecibos()
                report.generarPdf(data.data, ..._reportParams)
            }
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al generar el reporte' })
        }
    }
    const recaudacionesPorCuenta = async (filters, options = {}) => {
        const { type, reportParams, fileName } = getOptions(options)
        const params = cleanObject(filters)

        const { status, data } = await RecaudacionesServices.reporteValorados.recaudaciones.porCuenta(params)
        if (status === 200) {
            if (type === 'excel') {
                const report = new RecaudacionesPorCuentaExcel()
                report.excelReport(data.data, ...reportParams, fileName)
            } else {
                const report = new RecaudacionesPorCuentaPDF()
                report.generatePdf(data.data, reportParams)
            }
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al generar el reporte' })
        }
    }
    const recaudacionesPorTipoEstudiante = async (filters, options = {}) => {
        const { type, reportParams, fileName } = getOptions(options)
        const params = cleanObject(filters)

        const { status, data } = await RecaudacionesServices.reporteValorados.recaudaciones.porTipoEstudiante(params)
        if (status === 200) {
            if (type === 'excel') {
                const report = new ExcelTiposEstudiante()
                report.excelReport(data.data, ...reportParams, fileName)
            } else {
                const _reportParams = [
                    reportParams.filters.usuario,
                    reportParams.usuario,
                    reportParams.filters.fechaInicio,
                    reportParams.filters.fechaFin,
                    reportParams.modePrint,
                    reportParams.showAdvancedFilters,
                    reportParams.filters,
                    reportParams.isAdmin,
                    reportParams.unidad,
                    reportParams.filters.electronico,
                    reportParams.filters.caja,
                ]
                const report = new ReportTiposEstudiante()
                report.generarPdf(data.data, ..._reportParams)
            }
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al generar el reporte' })
        }
    }

    const cobroComisionBancaria = async (filters, options = {}) => {
        const { reportParams } = getOptions(options)
        const params = pick(filters, ['fecha_inicio', 'fecha_fin'])
        const { status, data } = await RecaudacionesServices.reporteValorados.cobroComisionBancaria(params)
        if (status === 200) {
            const _reportParams = [
                'Todos',
                reportParams.usuario,
                reportParams.filters.fechaInicio,
                reportParams.filters.fechaFin,
                reportParams.modePrint,
                reportParams.unidad,
                reportParams.filters.electronico,
            ]
            const report = new ReportCobroComisionBanco()
            report.generateReport(data.data, ..._reportParams)
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al generar el reporte' })
        }
    }
    const distribucionAportes = async (filters, options = {}) => {
        const { reportParams } = getOptions(options)
        const params = pick(filters, ['fecha_inicio', 'fecha_fin'])
        const { status, data } = await RecaudacionesServices.reporteValorados.distribucionAportes(params)
        if (status === 200) {
            const _reportParams = [
                'Todos',
                reportParams.usuario,
                reportParams.filters.fechaInicio,
                reportParams.filters.fechaFin,
                reportParams.modePrint,
            ]
            const report = new ReportDistribucionAportes()
            report.generateReport(data.data, ..._reportParams)
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al generar el reporte' })
        }
    }
    const resumenRecibos = async (filters, options = {}) => {
        const { type, reportParams } = getOptions(options)
        const params = cleanObject(filters)

        const { status, data } = await RecaudacionesServices.reporteValorados.resumenRecibos(params)
        if (status === 200) {
            if (type === 'excel') {
            } else {
                const _reportParams = [
                    reportParams.filters.usuario,
                    reportParams.usuario,
                    reportParams.filters.fechaInicio,
                    reportParams.filters.fechaFin,
                    reportParams.modePrint,
                    reportParams.showAdvancedFilters,
                    reportParams.filters,
                    reportParams.isAdmin,
                    reportParams.unidad,
                    reportParams.filters.electronico,
                    reportParams.filters.caja,
                ]
                const report = new ReportResumenRecibos()
                report.generarPdf(data.data, ..._reportParams)
            }
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al generar el reporte' })
        }
    }
    const recibosAnulados = async (filters, options = {}) => {
        const { type, reportParams, fileName } = getOptions(options)
        const params = cleanObject(filters)

        const { status, data } = await RecaudacionesServices.reporteValorados.recibosAnulados(params)
        if (status === 200) {
            if (type === 'excel') {
                const report = new ExcelAnulados()
                report.excelReport(data.data, ...reportParams, fileName)
            } else {
                const _reportParams = [
                    reportParams.filters.usuario,
                    reportParams.filters.fechaInicio,
                    reportParams.filters.fechaFin,
                    reportParams.usuario,
                    reportParams.modePrint,
                    reportParams.showAdvancedFilters,
                    reportParams.filters,
                    reportParams.isAdmin,
                    reportParams.unidad,
                    reportParams.filters.electronico,
                    reportParams.filters.caja,
                ]
                const report = new ReportAnulados()
                report.generatePDF(data.data, ..._reportParams)
            }
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al generar el reporte' })
        }
    }
    const valores = async (filters, options = {}) => {
        const { type, reportParams, fileName } = getOptions(options)
        const params = cleanObject(filters)

        const { status, data } = await RecaudacionesServices.reporteValorados.valores(params)
        if (status === 200) {
            if (type === 'excel') {
                const report = new ExcelValores()
                report.excelReport(data.data, ...reportParams, fileName)
            } else {
                const _reportParams = [
                    reportParams.filters.usuario,
                    reportParams.filters.fechaInicio,
                    reportParams.filters.fechaFin,
                    reportParams.usuario,
                    reportParams.modePrint,
                    reportParams.unidad,
                ]
                const report = new KardexValores()
                report.generatePDF(data.data, ..._reportParams)
            }
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al generar el reporte' })
        }
    }

    return {
        consolidado: {
            porUnidadCuenta: consolidadoPorUnidadCuenta,
            porConcepto: consolidadoPorConcepto,
            porUnidad: consolidadoPorUnidad,
            institucional: consolidadoInstitucional,
        },
        recaudaciones: {
            segunRecibos: recaudacionesSegunRecibos,
            porCuenta: recaudacionesPorCuenta,
            porTipoEstudiante: recaudacionesPorTipoEstudiante,
        },
        resumenRecibos,
        cobroComisionBancaria,
        distribucionAportes,
        recibosAnulados,
        valores,
    }
}
