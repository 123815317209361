import LoadingSpinner from 'Component/LoadingSpinner'
import useHttpToast from 'hooks/useHttpToast'
import { formatCurrency, formatDate, getDecimalPart, numberName } from 'utils'
import { Button } from 'modules/modular/Button'
import { ButtonIcon } from 'modules/modular/ButtonIcon'
import { DataTable } from 'modules/modular/DataTable'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import ContabilidadServices from 'services/contabilidad.service'
import { Confirm } from 'modules/modular/Modal'
import { verifyAccess } from 'utils/verifyAccess'
import ModalFormDetalleFondo from './components/ModalFormDetalleFondo'

export default function FormFondo({ tipo = 'view' }) {
    const detalleRef = useRef(null)
    const navigate = useNavigate()
    const { id } = useParams()
    const { pathname } = useLocation()
    const { showHttpToast } = useHttpToast()
    const [loading, setLoading] = useState(true)
    const [isLoading, setIsLoading] = useState({ buttonEnd: false, buttonGeneratePdf: false })
    const [isVisible, setIsVisible] = useState({
        modalFormDetalle: false,
        modalConfirmEnd: false,
        modalConfirmDelete: false,
    })
    const [fondo, setFondo] = useState(null)
    const [detalles, setDetalles] = useState([])
    const [selectedDetalle, setSelectedDetalle] = useState({
        index: null,
        value: null,
    })

    const enableUpdate = tipo !== 'view' && fondo?.activo && !fondo?.fecha_finalizado

    useEffect(() => {
        const loadInforme = async () => {
            if (id) {
                const { status, data } = await ContabilidadServices.fondo.view(id)
                if (status === 200) {
                    const { detalles, ...infoFondo } = data.data
                    setFondo(infoFondo)
                    setDetalles(detalles.map((d) => ({ ...d, actividad: d.categoria_programatica })))
                    setLoading(false)
                } else {
                    showHttpToast(status, { detail: data.message })
                    navigate('/')
                }
            }
        }
        loadInforme()
    }, [id])

    const show = (name) => setIsVisible((prev) => ({ ...prev, [name]: true }))
    const hide = (name) => setIsVisible((prev) => ({ ...prev, [name]: false }))

    const handleAction = (action, detalle = null) => {
        setSelectedDetalle(detalle)
        if (['create', 'update'].includes(action)) {
            show('modalFormDetalle')
        } else if (action === 'delete') {
            show('modalConfirmDelete')
        }
    }

    const optionsTemplate = ({ data, rowIndex }) => (
        <div>
            <ButtonIcon
                variant='danger'
                className='me-2'
                onClick={() => {
                    handleAction('delete', {
                        index: rowIndex,
                        value: data,
                    })
                }}
            >
                <span className='bi-trash lh-1' />
            </ButtonIcon>
            <ButtonIcon
                onClick={() => {
                    handleAction('update', {
                        index: rowIndex,
                        value: data,
                    })
                }}
            >
                <span className='bi-pencil lh-1' />
            </ButtonIcon>
        </div>
    )
    const columns = useMemo(
        () => [
            {
                field: 'numero',
                header: 'N°',
                cell: ({ rowIndex }) => rowIndex + 1,
                cellClassName: 'text-center',
            },
            {
                field: 'fecha',
                header: 'FECHA',
                cellClassName: 'text-center',
                cell: ({ value }) => formatDate(value, 'd/m/Y'),
            },
            { field: 'detalle', header: 'DETALLE', cellClassName: 'text-center' },
            { field: 'numero_documento', header: 'N° DOCUMENTO', cellClassName: 'text-center' },
            { field: 'tipo_documento', header: 'TIPO DOCUMENTO', cellClassName: 'text-center' },
            {
                field: 'monto_total',
                header: 'MONTO TOTAL',
                cell: ({ value }) => formatCurrency(value),
                cellClassName: 'text-end',
            },
            {
                id: 'retenciones',
                header: 'RETENCIONES',
                columns: [
                    {
                        field: 'rciva',
                        header: '13% RC-IVA',
                        headerClassName: 'text-nowrap',
                        cell: ({ value }) => formatCurrency(value),
                        cellClassName: 'text-end',
                    },
                    {
                        field: 'it',
                        header: '3% I.T.',
                        headerClassName: 'text-nowrap',
                        cell: ({ value }) => formatCurrency(value),
                        cellClassName: 'text-end',
                    },
                    {
                        field: 'iue',
                        header: '5% I.U.E',
                        headerClassName: 'text-nowrap',
                        cell: ({ value }) => formatCurrency(value),
                        cellClassName: 'text-end',
                    },
                ],
            },
            {
                field: 'liquido_pagable',
                header: 'LIQUIDO PAGABLE',
                cell: ({ value }) => formatCurrency(value),
                cellClassName: 'text-end',
            },
            {
                field: 'categoria_programatica',
                header: 'CATEGORIA PROGRAMATICA',
                cellClassName: 'text-center',
                style: { width: '10.5rem' },
            },
            {
                field: 'partida',
                header: 'PARTIDA PRESUPUESTARIA',
                cellClassName: 'text-center',
                style: { width: '10.5rem' },
            },
            ...(enableUpdate
                ? [
                      {
                          id: 'options',
                          header: 'OPCIONES',
                          cell: optionsTemplate,
                          cellClassName: 'text-center',
                      },
                  ]
                : []),
        ],
        [enableUpdate],
    )

    /** Establece el detalle seleccionado a su valor inicial. */
    const resetSelectedDetalle = () => {
        setSelectedDetalle({
            index: null,
            value: null,
        })
    }
    /** Registra/Edita un detalle del fondo */
    const handleCreateUpdate = async ({ documento, detalle, setErrors, setLoading, resetDetalle }) => {
        const value = { ...documento, ...detalle }
        const _detalles =
            selectedDetalle.index === null
                ? [...detalles, value]
                : [...detalles.map((d, i) => (i === selectedDetalle.index ? value : d))]
        // Verificar que el saldo sea mayor a cero
        const _totales = getTotales(_detalles)
        const saldo = fondo?.monto_asignado - _totales.total_liquido_pagable
        if (saldo > 0) {
            const { status, data } = value.id
                ? await ContabilidadServices.fondoDetalle.update(value.id, value)
                : await ContabilidadServices.fondoDetalle.create(value)
            showHttpToast(status, { detail: data.message })
            if (status === 200) {
                // Edición
                getDetalles()
                hide('modalFormDetalle')
            } else if (status === 201) {
                // Creación
                getDetalles()
                resetDetalle()
                setErrors({})
                detalleRef.current?.focus()
            }
        } else {
            setErrors({ liquido_pagable: 'El líquido pagable no debe exceder el saldo' })
        }
        setLoading(false)
    }
    /** Elimina un detalle del fondo. */
    const handleDelete = async () => {
        if (selectedDetalle.value.id) {
            const { status, data } = await ContabilidadServices.fondoDetalle.delete(selectedDetalle.value.id)
            showHttpToast(status, { detail: data.message })
            if (status === 200) {
                getDetalles()
                hide('modalConfirmDelete')
            }
        }
    }
    /**
     * Obtiene los totales de los detalles del reembolso.
     * @param {Array<object>} det Array de detalles del reembolso.
     */
    const getTotales = (det) => {
        return det.reduce(
            (carry, d) => {
                carry.total_monto_total += d.monto_total * 1
                carry.total_rciva += d.rciva * 1
                carry.total_it += d.it * 1
                carry.total_iue += d.iue * 1
                carry.total_liquido_pagable += d.liquido_pagable * 1
                return carry
            },
            {
                total_monto_total: 0,
                total_rciva: 0,
                total_it: 0,
                total_iue: 0,
                total_liquido_pagable: 0,
            },
        )
    }
    /** Llama al servicio para finalizar el reembolso del fondo. */
    const handleEnd = async () => {
        setIsLoading((prev) => ({ ...prev, buttonEnd: true }))
        hide('modalConfirmEnd')
        const { status, data } = await ContabilidadServices.fondo.end(fondo.id)
        showHttpToast(status, { detail: data.message })
        setIsLoading((prev) => ({ ...prev, buttonEnd: false }))
        if (status === 200) {
            navigate(`/contabilidad/fondo/${fondo.id}`)
        }
    }
    /** Obtiene el pdf del reembolso del fondo. */
    const generatePdf = async () => {
        setIsLoading((prev) => ({ ...prev, buttonGeneratePdf: true }))
        const { status, data } = await ContabilidadServices.fondo.pdf(fondo.id)
        if (status === 200) {
            const pdfContent = data
            // Crear una URL local para el blob recibido
            const blobUrl = window.URL.createObjectURL(new Blob([pdfContent], { type: 'application/pdf' }))

            // Abrir una nueva pestaña con el PDF
            window.open(blobUrl, '_blank')
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al obtener el PDF' })
        }
        setIsLoading((prev) => ({ ...prev, buttonGeneratePdf: false }))
    }
    /** Obtiene los detalles del fondo. */
    const getDetalles = async () => {
        if (fondo.id) {
            const { status, data } = await ContabilidadServices.fondo.details(fondo.id)
            if (status === 200) setDetalles(data.data.map((d) => ({ ...d, actividad: d.categoria_programatica })))
        }
    }

    const totales = getTotales(detalles)

    const footerElement = (
        <tfoot>
            <tr>
                <th colSpan={5} className='text-center'>
                    TOTALES
                </th>
                <th className='text-end'>{formatCurrency(totales.total_monto_total)}</th>
                <th className='text-end'>{formatCurrency(totales.total_rciva)}</th>
                <th className='text-end'>{formatCurrency(totales.total_it)}</th>
                <th className='text-end'>{formatCurrency(totales.total_iue)}</th>
                <th className='text-end'>{formatCurrency(totales.total_liquido_pagable)}</th>
                <th colSpan={2}></th>
            </tr>
            <tr>
                <th colSpan={9} className='text-end'>
                    {fondo?.tipo === 'R' ? 'SALDO EN CAJA' : 'DEPOSITO BANCARIO'}
                </th>
                <th className='text-end'>{formatCurrency(fondo?.monto_asignado - totales.total_liquido_pagable)}</th>
                <th colSpan={2}></th>
            </tr>
        </tfoot>
    )

    return loading ? (
        <LoadingSpinner />
    ) : (
        <div className='h-100 p-4'>
            <div className='bg-blue text-white text-center py-3 px-4'>
                <h2 className='mb-0 fw-normal'>
                    {fondo.tipo === 'R' ? `Fondo Rotatorio Nro ${fondo.numero ?? '#'}` : 'Cierre de Fondo en Avance'}
                </h2>
            </div>
            <div className='bg-white p-3'>
                <div className='d-flex mb-3'>
                    <div>
                        <span className='fw-bold me-2'>BENEFICIARIO:</span>
                        {fondo.beneficiario}
                        <br />
                        <span className='fw-bold me-2'>UNIDAD:</span>
                        {fondo.actividad}
                        <br />
                        <span className='fw-bold me-2'>MONTO ASIGNADO:</span>
                        {fondo ? formatCurrency(fondo.monto_asignado) : '0.00'}{' '}
                        {fondo &&
                            `- ${numberName(fondo.monto_asignado).toUpperCase()} ${getDecimalPart(
                                fondo.monto_asignado,
                            )}/100 BOLIVIANOS`}
                        <br />
                        <span className='fw-bold me-2'>FONDOS:</span>
                        {fondo.fuente_fondo}
                        <br />
                    </div>
                    <div className='ms-auto'>
                        {enableUpdate && (
                            <Button
                                startIcon={<span className='bi-plus-lg' />}
                                onClick={() => show('modalFormDetalle')}
                            >
                                Añadir
                            </Button>
                        )}
                    </div>
                </div>
                <DataTable
                    columns={columns}
                    values={detalles}
                    className='table-bordered'
                    containerClassName='mb-3'
                    footerElement={footerElement}
                    rowKeyField='id'
                    defaultAlignHeader='center'
                />
            </div>
            <div className='bg-blue text-center p-3'>
                {(tipo === 'view' || (enableUpdate && fondo.cantidad_finalizado === 0)) && (
                    <Button
                        variant='secondary'
                        disabled={isLoading.buttonEnd || isLoading.buttonGeneratePdf}
                        startIcon={<span className='bi-chevron-left' />}
                        className='me-3'
                        onClick={() => {
                            navigate(
                                verifyAccess(
                                    [
                                        'AdministradorContabilidad',
                                        'AdministradorContabilidadDA',
                                        'AdministradorPresupuestos',
                                        'ReportesPresupuestosDA',
                                    ],
                                    'OR',
                                )
                                    ? `/${pathname.includes('/presupuestos') ? 'presupuestos' : 'contabilidad'}/fondo`
                                    : `/contabilidad/fondo-${fondo.tipo === 'A' ? 'avance' : 'rotatorio'}`,
                            )
                        }}
                    >
                        Volver
                    </Button>
                )}
                {verifyAccess(['BeneficiarioFondoRotatorio', 'BeneficiarioFondoAvance'], 'OR') && (
                    <Button
                        className='me-3'
                        onClick={generatePdf}
                        disabled={isLoading.buttonEnd}
                        loading={isLoading.buttonGeneratePdf}
                        startIcon={<span className='bi-file-earmark-pdf' />}
                    >
                        Generar PDF
                    </Button>
                )}
                {enableUpdate && (
                    <Button
                        variant='success'
                        className='me-3'
                        onClick={() => show('modalConfirmEnd')}
                        disabled={isLoading.buttonGeneratePdf}
                        loading={isLoading.buttonEnd}
                        startIcon={<span className='bi-check-lg' />}
                    >
                        Verificar
                    </Button>
                )}
            </div>
            <Confirm
                show={isVisible.modalConfirmEnd}
                onHide={() => hide('modalConfirmEnd')}
                onReject={() => hide('modalConfirmEnd')}
                onAccept={handleEnd}
                style={{ width: '27.5rem' }}
            >
                <span className='lh-1' style={{ fontSize: '1.1rem' }}>
                    Una vez verifique el fondo no podrá realizar más modificaciones.
                    <br />
                    Recuerde que solo debe verificar antes de enviar el documento para su revisión.
                    <br />
                    ¿Esta seguro de <span className='text-primary'>VERIFICAR</span> el
                    {fondo.tipo === 'R' ? ` Fondo Rotatorio N° ${fondo.numero}` : ' Cierre del Fondo en avance'}?
                </span>
            </Confirm>
            <Confirm
                show={isVisible.modalConfirmDelete}
                onHide={() => hide('modalConfirmDelete')}
                onReject={() => hide('modalConfirmDelete')}
                onAccept={handleDelete}
                onExited={resetSelectedDetalle}
            >
                <span className='lh-1' style={{ fontSize: '1.1rem' }}>
                    ¿Esta seguro de <span className='text-primary'>ELIMINAR</span> el Detalle{' '}
                    {selectedDetalle.value?.detalle} con Nro. de Documento {selectedDetalle.value?.numero_documento}?
                </span>
            </Confirm>
            <ModalFormDetalleFondo
                show={isVisible.modalFormDetalle}
                onHide={() => hide('modalFormDetalle')}
                onExited={resetSelectedDetalle}
                fondo={fondo}
                selected={selectedDetalle.value}
                onSubmit={handleCreateUpdate}
            />
        </div>
    )
}
