import { CustomDate } from './CustomDate'
/**
 * Formatea una fecha según un formato definido.
 * @param date Fecha a formatear. Acepta fechas js, cadenas de texto y fechas en formato unix.
 * @param format Formato que tomara la fecha, igual al formato de PHP, por defecto 'Y-m-d'.
 * @param defaultValue Valor devuelto si la fecha es invalida. Por defecto string vacío.
 * @returns Retorna la fecha con el formato especificado, en caso que fecha sea invalida retorna el valor por defecto.
 */
export function formatDate(date, format = 'Y-m-d', options) {
    return new CustomDate(date, { unix: options?.unix }).format(format) || (options?.defaultValue ?? '')
}
