import { Modal } from 'modules/modular/Modal'
import SelectCuenta from './SelectCuenta'

/**
 * Modal que permite seleccionar una cuenta de una lista.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {Array<Object>} props.cuentas - Lista de cuentas disponibles.
 * @param {Function} props.onSelectCuenta - Función que se ejecuta cuando se confirma la selección de una cuenta.
 * @param {Function} props.searchCuenta - Función para buscar cuentas por código o nombre.
 * @param {string} props.initialSearcherValue - Valor inicial que se mostrara en el input de búsqueda.
 */
export default function ModalSelectCuenta({ cuentas, onSelectCuenta, searchCuenta, initialSearcherValue, ...props }) {
    return (
        <Modal style={{ width: '75rem' }} closeOnBackdrop={false} {...props}>
            <div className='px-3 py-2 border-bottom fs-4 fw-medium'>Cuentas</div>
            <SelectCuenta
                cuentas={cuentas}
                onSelectCuenta={onSelectCuenta}
                onCancel={() => props.onHide?.()}
                searchCuenta={searchCuenta}
                initialSearcherValue={initialSearcherValue}
            />
        </Modal>
    )
}
