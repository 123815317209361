import { DataTable } from 'modules/modular/DataTable'
import { formatCurrency } from 'utils'

const columns = [
    {
        field: 'codigo',
        header: 'Código',
        headerClassName: 'fw-medium',
        style: { width: '10rem' },
    },
    {
        field: 'nombre',
        header: 'Nombre',
        headerClassName: 'fw-medium',
    },
    {
        field: 'unidad',
        header: 'Unidad',
        headerClassName: 'fw-medium',
    },
    {
        field: 'cantidad',
        header: 'Cantidad',
        headerClassName: 'fw-medium',
        cellClassName: 'text-center',
        style: { width: '6rem' },
    },
    {
        field: 'precio',
        header: 'Precio Unit. (Bs)',
        headerClassName: 'fw-medium',
        cellClassName: 'text-end',
        style: { width: '9rem' },
    },
    {
        id: 'precio_total',
        header: 'Importe (Bs)',
        headerClassName: 'fw-medium',
        cell: ({ data }) => formatCurrency(data.precio * data.cantidad),
        cellClassName: 'text-end',
        style: { width: '10rem' },
    },
]

export default function ListCuentasAsociadas({ cuentas, ...props }) {
    return (
        <div {...props}>
            <div className='bg-blue rounded text-white select-none mb-3' style={{ padding: '.75rem 1rem' }}>
                <span className='fw-bold' style={{ fontSize: '1.125rem' }}>
                    CUENTAS ASOCIADAS
                </span>
            </div>
            <div className='mx-3'>
                {cuentas.length ? (
                    <DataTable
                        values={cuentas}
                        columns={columns}
                        defaultAlignHeader='center'
                        tableBodyStyle={{ fontSize: '.875rem' }}
                        emptyMessage={<div className='fw-medium text-center'>Sin cuentas asociadas</div>}
                    />
                ) : (
                    <div className='fw-medium text-center'>Sin cuentas asociadas</div>
                )}
            </div>
        </div>
    )
}
