import { days, months, shortDays, shortMonths } from './constants'
export class CustomDate {
    constructor(value, options) {
        let date = value
        if (typeof value === 'string') {
            if (/^\d{4}-\d{2}-\d{2}$/.test(value)) {
                value = `${value.replace(/\//g, '-')}T00:00:00`
            }
            date = new Date(value)
        } else if (typeof value === 'number') {
            date = new Date(options?.unix ? value * 1000 : value)
        }
        this.date = date instanceof Date && !isNaN(date.getTime()) ? date : (options?.defaultValue ?? null)
    }
    isValid() {
        return this.date !== null
    }
    getDate() {
        return this.date
    }
    getTime() {
        return this.date ? this.date.getTime() : NaN
    }
    getUnixTimestamp() {
        return this.date ? Math.floor(this.date.getTime() / 1000) : NaN
    }
    setStringTime(time) {
        if (this.date) {
            const regexTime = /^\d{1,2}:\d{2}(:\d{2}(\.\d{0,4})?)?$/
            if (typeof time === 'string' && regexTime.test(time)) {
                const [hours, min, seconds] = time.split(':')
                const [sec, ms] = (seconds ?? '').split('.')
                this.date.setUTCHours(
                    parseInt(hours),
                    parseInt(min),
                    sec ? parseInt(sec) : undefined,
                    ms ? parseInt(ms) : undefined,
                )
            }
        }
        return this
    }
    add({ years, months, days, hours, minutes, seconds, milliseconds }) {
        if (this.date) {
            this.date.setFullYear(
                this.date.getFullYear() + (years ?? 0),
                this.date.getMonth() + (months ?? 0),
                this.date.getDate() + (days ?? 0),
            )
            this.date.setUTCHours(
                this.date.getHours() + (hours ?? 0),
                this.date.getMinutes() + (minutes ?? 0),
                this.date.getSeconds() + (seconds ?? 0),
                this.date.getMilliseconds() + (milliseconds ?? 0),
            )
        }
        return this
    }
    subtract({ years, months, days, hours, minutes, seconds, milliseconds }) {
        if (this.date) {
            this.date.setFullYear(
                this.date.getFullYear() - (years ?? 0),
                this.date.getMonth() - (months ?? 0),
                this.date.getDate() - (days ?? 0),
            )
            this.date.setUTCHours(
                this.date.getHours() - (hours ?? 0),
                this.date.getMinutes() - (minutes ?? 0),
                this.date.getSeconds() - (seconds ?? 0),
                this.date.getMilliseconds() - (milliseconds ?? 0),
            )
        }
        return this
    }
    format(format = 'Y-m-d') {
        let formatted = ''
        if (this.date) {
            const year = this.date.getFullYear()
            const month = this.date.getMonth() + 1 // 1 es Enero y 12 es Diciembre
            const day = this.date.getDate()
            const weekDay = this.date.getDay()
            const weekDayISO = weekDay === 0 ? 7 : weekDay // ISO-8601
            format.split('').forEach((value) => {
                let add = ''
                switch (value) {
                    case 'Y':
                        add = year
                        break
                    case 'y':
                        add = year.toString().slice(-2)
                        break
                    case 'm':
                        add = `0${month}`.slice(-2)
                        break
                    case 'n':
                        add = month
                        break
                    case 'M':
                        add = shortMonths[month]
                        break
                    case 'F':
                        add = months[month]
                        break
                    case 'd':
                        add = `0${day}`.slice(-2)
                        break
                    case 'j':
                        add = day
                        break
                    case 'w':
                        add = weekDay
                        break
                    case 'N':
                        add = weekDayISO
                        break
                    case 'D':
                        add = shortDays[weekDayISO]
                        break
                    case 'l':
                        add = days[weekDayISO]
                        break
                    case 'H':
                        add = String(this.date?.getHours()).padStart(2, '0')
                        break
                    case 'i':
                        add = String(this.date?.getMinutes()).padStart(2, '0')
                        break
                    case 's':
                        add = String(this.date?.getSeconds()).padStart(2, '0')
                        break
                    default:
                        add = value
                        break
                }
                formatted = `${formatted}${add}`
            })
        }
        return formatted
    }
    toString() {
        return this.date?.toString() ?? ''
    }
}
