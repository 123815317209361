import { empty } from 'modules/modular/utils'

export function validatePersona(values) {
    if (values.id) {
        return null
    }
    const errors = {}
    if (!values.documento.trim()) errors.documento = 'Requerido'
    if (!values.fecha_nacimiento) errors.fecha_nacimiento = 'Requerido'
    if (!values.apellido_1.trim()) errors.apellido_1 = 'Requerido'
    if (!values.nombre_1.trim()) errors.nombre_1 = 'Requerido'
    return empty(errors) ? null : errors
}
