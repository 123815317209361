import { forwardRef, useImperativeHandle } from 'react'

export const DatosCuentaRef = forwardRef(({ validate, reset, get }, ref) => {
    useImperativeHandle(ref, () => ({
        validateCuenta() {
            return validate()
        },
        resetCuenta() {
            reset()
        },
        getCuenta() {
            return get()
        },
    }))
    return null
})
