import { BaseRequest } from './request/baseRequest'
import { GenericRequest } from './request/request'

const AuthServices = {
    login: (credentials) => BaseRequest.post('auth/login', credentials),
    info: () => GenericRequest.get('auth/info'),
    changeDefaultPassword: (data) => GenericRequest.post('auth/change-default-password', data),
}

export default AuthServices
