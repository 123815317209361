import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DataTable } from 'modules/modular/DataTable'
import { ButtonIcon } from 'modules/modular/ButtonIcon'
import { Confirm } from 'modules/modular/Modal'
import ContabilidadServices from 'services/contabilidad.service'
import { initialPagination } from 'data/constants'
import { formatCurrency, formatDate } from 'utils'
import useFilters from 'hooks/useFilters'
import useHttpToast from 'hooks/useHttpToast'
import { Button } from 'modules/modular/Button'

export default function ListFondo({ tipo }) {
    const navigate = useNavigate()
    const { showHttpToast } = useHttpToast()
    const { filters, handlePage, handlePageSize } = useFilters()
    const [isVisible, setIsVisible] = useState({ modalConfirmUpdate: false, modalConfirmDelete: false })
    const [selected, setSelected] = useState(null)
    const [fondos, setFondos] = useState([])
    const [pagination, setPagination] = useState(initialPagination)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        load()
    }, [tipo, filters])

    const load = () => {
        if (['A', 'R'].includes(tipo)) {
            loadList()
            verifyEnableCreate()
        } else {
            setFondos([])
            setPagination(initialPagination)
        }
    }
    const loadList = async () => {
        const getList = tipo === 'R' ? ContabilidadServices.fondoRotatorio.list : ContabilidadServices.fondoAvance.list
        const { status, data } = await getList(filters)
        if (status === 200) {
            setFondos(data.data)
            setPagination(data.pagination)
        } else {
            showHttpToast(status, { detail: data.message })
        }
    }
    const verifyEnableCreate = async () => {
        if (tipo === 'R') {
            const { status, data } = await ContabilidadServices.fondoRotatorio.hasActive()
            if (status === 200) setLoading(data.data)
        }
    }

    const show = (name) => setIsVisible((prev) => ({ ...prev, [name]: true }))
    const hide = (name) => setIsVisible((prev) => ({ ...prev, [name]: false }))

    const handleAction = (action, rowData) => {
        if (action === 'view') {
            navigate(`/contabilidad/fondo/${rowData.id}`)
        } else if (action === 'update') {
            if (rowData.fecha_finalizado) {
                setSelected(rowData)
                show('modalConfirmUpdate')
            } else {
                navigate(`/contabilidad/fondo/update/${rowData.id}`)
            }
        } else if (action === 'create') {
            handleCreate()
        } else if (action === 'delete') {
            setSelected(rowData)
            show('modalConfirmDelete')
        }
    }

    const optionsTemplate = ({ data }) => (
        <div className='d-flex justify-content-center'>
            <div style={{ width: '6.5rem' }}>
                <ButtonIcon
                    variant='outline-primary'
                    className='me-2'
                    onClick={() => handleAction('view', data)}
                    style={{ fontSize: '1.2rem' }}
                >
                    <span className='bi-eye lh-1' style={{ fontSize: '1rem' }} />
                </ButtonIcon>
                {data.activo && (!data.fecha_finalizado || data.limite_desfinalizar > 0) && (
                    <ButtonIcon onClick={() => handleAction('update', data)} style={{ fontSize: '1.2rem' }}>
                        <span className='bi-pencil lh-1' style={{ fontSize: '1rem' }} />
                    </ButtonIcon>
                )}
                {data.activo && data.cantidad_finalizado === 0 && data.tipo === 'R' && (
                    <ButtonIcon
                        variant='danger'
                        className='ms-2'
                        onClick={() => handleAction('delete', data)}
                        style={{ fontSize: '1.2rem' }}
                    >
                        <span className='bi-trash lh-1' style={{ fontSize: '1rem' }} />
                    </ButtonIcon>
                )}
            </div>
        </div>
    )
    const columns = useMemo(
        () => [
            ...(tipo === 'A' ? [] : [{ field: 'numero', header: 'Número', cellClassName: 'text-center' }]),
            {
                field: 'monto_asignado',
                header: 'Monto Bs.',
                cell: ({ value }) => formatCurrency(value),
                cellClassName: 'text-end',
            },
            { field: 'fuente_fondo', header: 'Fondos', cellClassName: 'text-center' },
            {
                field: 'fecha_creacion',
                header: 'Fecha Registro',
                cell: ({ value }) => formatDate(value, 'd/m/Y'),
                cellClassName: 'text-center',
            },
            {
                field: 'fecha_finalizado',
                header: 'Verificado',
                cell: ({ value }) => (value ? 'Si' : 'No'),
                cellClassName: 'text-center',
            },
            { field: 'id', header: 'Opciones', cell: optionsTemplate, style: { width: '10rem' } },
        ],
        [tipo],
    )

    const handleCreate = async () => {
        setLoading(true)
        const { status, data } = await ContabilidadServices.fondoRotatorio.create()
        showHttpToast(status, {
            detail: status === 422 ? 'No tiene un monto asignado para el Fondo Rotatorio' : data.message,
        })
        if (status === 201) {
            load()
        }
        setLoading(false)
    }
    const handleDelete = async () => {
        if (selected?.id) {
            const { status, data } = await ContabilidadServices.fondoRotatorio.delete(selected.id)
            showHttpToast(status, { detail: data.message })
            if (status === 200) {
                load()
                hide('modalConfirmDelete')
            }
        }
    }
    const handleConfirmUpdate = async () => {
        if (selected?.id) {
            const { status, data } = await ContabilidadServices.fondo.reverseEnd(selected.id)
            if (status === 200) {
                navigate(`/contabilidad/fondo/update/${selected.id}`)
            } else {
                showHttpToast(status, { detail: data.message })
            }
        }
    }

    const clearSelected = () => setSelected(null)

    return (
        <div className='d-flex h-100 p-4'>
            <div className='container p-0 mx-auto my-0 h-100 bg-white'>
                <div className='d-flex align-items-center bg-blue text-white py-3 px-4'>
                    <h2 className='mb-0 fw-normal'>
                        {tipo === 'R' ? 'Lista de Fondos Rotatorio' : 'Lista de Fondos en Avance'}
                    </h2>
                    {tipo === 'R' && (
                        <div className='ms-auto'>
                            <Button
                                startIcon={<span className='bi-plus-lg' />}
                                onClick={() => handleAction('create')}
                                disabled={loading}
                                variant='light'
                            >
                                Nuevo Fondo
                            </Button>
                        </div>
                    )}
                </div>
                <div className='bg-white p-3'>
                    <DataTable
                        columns={columns}
                        values={fondos}
                        defaultAlignHeader='center'
                        lazy
                        pagination
                        currentPage={pagination.currentPage}
                        pageSize={pagination.pageSize}
                        totalItems={pagination.totalRecords}
                        onPageChange={handlePage}
                        onPageSizeChange={handlePageSize}
                    />
                    <Confirm
                        show={isVisible.modalConfirmUpdate}
                        onHide={() => hide('modalConfirmUpdate')}
                        onReject={() => hide('modalConfirmUpdate')}
                        onAccept={() => handleConfirmUpdate()}
                        onExited={clearSelected}
                    >
                        <span className='lh-1' style={{ fontSize: '1.1rem' }}>
                            El
                            <span className='fw-bold mx-2'>
                                Fondo {selected?.tipo === 'R' ? `Rotatorio N° ${selected?.numero}` : 'en Avance'}
                            </span>
                            fue verificado anteriormente. Usted podrá editarlo {selected?.limite_desfinalizar} vez/veces
                            más.
                            <br />
                            ¿Esta seguro de <span className='text-primary'>EDITAR</span> el Fondo{' '}
                            {selected?.tipo === 'R' ? 'Rotatorio' : 'en Avance'}?
                        </span>
                    </Confirm>
                    <Confirm
                        show={isVisible.modalConfirmDelete}
                        onHide={() => hide('modalConfirmDelete')}
                        onReject={() => hide('modalConfirmDelete')}
                        onAccept={handleDelete}
                        onExited={clearSelected}
                    >
                        <span className='lh-1' style={{ fontSize: '1.1rem' }}>
                            ¿Esta seguro de <span className='text-primary'>ELIMINAR</span> el Fondo{' '}
                            {selected?.tipo === 'R' ? `Rotatorio Nro ${selected?.numero}` : 'en Avance'}?
                        </span>
                    </Confirm>
                </div>
            </div>
        </div>
    )
}
