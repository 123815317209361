import { Button } from 'modules/modular/Button'
import { DataTable } from 'modules/modular/DataTable'
import { FieldGroup } from 'modules/modular/FieldGroup'
import { Input } from 'modules/modular/Input'
import { useEffect, useRef, useState } from 'react'

const columns = [
    {
        field: 'apellido_1',
        header: 'Apellidos',
        headerClassName: 'bg-secondary-subtle',
        cell: ({ data }) => `${data.apellido_1} ${data.apellido_2 ?? ''} ${data.apellido_3 ?? ''}`,
    },
    {
        field: 'nombre_1',
        header: 'Nombres',
        headerClassName: 'bg-secondary-subtle',
        cell: ({ data }) => `${data.nombre_1} ${data.nombre_2 ?? ''}`,
    },
    {
        field: 'documento',
        header: 'Documento',
        headerClassName: 'bg-secondary-subtle',
        cellClassName: 'text-center',
    },
    {
        field: 'complemento_documento',
        header: 'Comp.',
        headerClassName: 'bg-secondary-subtle',
        default: '-',
        cellClassName: 'text-center',
    },
    {
        field: 'codigo',
        header: 'Código',
        headerClassName: 'bg-secondary-subtle',
        default: '-',
        cellClassName: 'text-center',
    },
]

/**
 * Selector de persona.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {Array<Object>} props.personas - Lista de personas disponibles.
 * @param {Function} props.onSelectPersona - Función que se ejecuta cuando se confirma la selección de una persona.
 * @param {Function} props.onCancel - Función que se ejecuta al cancelar la selección de persona.
 * @param {Function} props.searchPersona - Función que buscar personas por documento o código.
 * @param {string} props.initialSearcherValue - Valor inicial que se mostrara en el input de búsqueda.
 */
export default function SelectPersona({ personas, onSelectPersona, onCancel, searchPersona, initialSearcherValue }) {
    const tableRef = useRef(null)
    const searcherInputRef = useRef(null)
    const buttonSeleccionarRef = useRef(null)
    const [persona, setPersona] = useState(null)
    const [searchValue, setSearchValue] = useState('')

    useEffect(() => {
        if (typeof initialSearcherValue === 'string') {
            setSearchValue(initialSearcherValue)
        }
    }, [])

    /** Maneja la confirmación de selección de una persona. */
    const handleSelectPersona = () => {
        if (persona) {
            onSelectPersona?.(persona)
        }
    }

    return (
        <div className='p-3'>
            {searchPersona && (
                <FieldGroup label='Buscar:' labelClassName='fw-medium' inline>
                    <Input
                        ref={searcherInputRef}
                        value={searchValue}
                        onChange={(e) => {
                            const value = e.target.value.toUpperCase()
                            setSearchValue(value)
                            searchPersona({ documento: value })
                        }}
                        onKeyDown={(e) => (e.key === ' ' || e.key === 'Spacebar') && e.preventDefault()}
                        onKeyUp={(e) => e.key === 'Enter' && tableRef.current.focusRow(0)}
                        placeholder='Buscar por Documento'
                        autoFocus
                    />
                </FieldGroup>
            )}
            <DataTable
                innerRef={tableRef}
                values={personas}
                columns={columns}
                selectionMode='single'
                selectionOn='row'
                selection={persona}
                onSelectionChange={(selection) => {
                    setPersona(selection)
                    if (selection) {
                        setTimeout(() => {
                            buttonSeleccionarRef.current.focus({ focusVisible: true })
                        }, 100)
                    }
                }}
                defaultAlignHeader='center'
                className='mb-5'
                keyboardNavigation={{ upRowKey: 'w', downRowKey: 's' }}
                onRowKeyUp={(e) => e.key.toLowerCase() === 'q' && searcherInputRef.current.focus()}
            />
            <div className='py-2 text-center'>
                <Button
                    ref={buttonSeleccionarRef}
                    onClick={handleSelectPersona}
                    onKeyUp={(e) => e.key.toLowerCase() === 'q' && tableRef.current.focusRow(0)}
                    startIcon={<span className='bi-check-lg' />}
                    disabled={!persona}
                >
                    Seleccionar
                </Button>
                <Button
                    variant='secondary'
                    className='ms-3'
                    startIcon={<span className='bi-x-lg' />}
                    onClick={onCancel}
                >
                    Cancelar
                </Button>
            </div>
        </div>
    )
}
