import React, { useEffect, useMemo, useRef, useState } from 'react'
import { classNames, compareData, debounce, joinStyles, propFromName, sortObjects } from '../utils'
import { orders } from './interfaces'
import { DataTableRef } from './DataTableRef'
import { DataTableHeader } from './DataTableHeader'
import DataTableFooter from './DataTableFooter'
import { Pagination } from '../Pagination'
import { BaseTable, BaseTbody, BaseTd, BaseTr } from '../base/BaseDataTable'
import DataTableRow from './DataTableRow'
import { getColumnId } from './helpers/getColumnId'
import { getLeafColumns } from './helpers/getLeafColumns'
// Valores iniciales del orden del DataTable.
const initialSort = { field: orders.noOrder, order: orders.noOrder }
const initialPagination = {
    currentPage: 0,
    pageSize: 10,
    totalItems: 0,
    totalPages: 0,
    offset: 0,
    limit: 10,
}
export function DataTable({
    columns = [],
    values,
    rowKeyField,
    headerElement,
    defaultAlignHeader,
    footerElement,
    tableClassName,
    tableStyle,
    tableHeaderClassName,
    tableHeaderStyle,
    tableBodyClassName,
    tableBodyStyle,
    tableFooterClassName,
    tableFooterStyle,
    responsive,
    headerSticky,
    tableWrapperClassName,
    tableWrapperStyle,
    emptyMessage,
    groupRowsMode,
    groupRowsBy,
    subheaderTemplate,
    subheaderClassName,
    subheaderStyle,
    lazy,
    filterable,
    filterDelay,
    defaultFilters,
    onFilter,
    onSort,
    defaultSortField,
    defaultSortOrder,
    selectionMode,
    selectionOn,
    selection,
    onSelectionChange,
    isRowSelectable,
    keyboardNavigation,
    onRowKeyUp,
    pagination,
    currentPage,
    totalItems,
    pageSize,
    pageSizeOptions,
    onPageChange,
    onPageSizeChange,
    paginationClassName,
    paginationStyle,
    pagesToShow,
    rowClassName,
    rowStyle,
    innerRef,
    skeletonRows = 10,
    onVirtualScroll,
    ...props
}) {
    const wrapperRef = useRef(null)
    const headerRef = useRef(null)
    const rowsRef = useRef([])
    const rowsIndexRef = useRef({})
    const finalList = useRef(false)
    const [loading, setLoading] = useState(false)
    const [rows, setRows] = useState([])
    const [allSelected, setAllSelected] = useState(false)
    const [searching, setSearching] = useState(false)
    const [filters, setFilters] = useState(null)
    const [sort, setSort] = useState({
        field: defaultSortField ?? null,
        order: (defaultSortField && defaultSortOrder) || orders.noOrder,
    })
    const { headers, columnFilters, hasFilters, initFilters, headerGroups, footerGroups } = useMemo(() => {
        let maxDepth = 0
        const createHeader = (index, column, depth) => {
            depth = depth ?? 0
            maxDepth = depth > maxDepth ? depth : maxDepth
            const id = getColumnId(column, String(index))
            return {
                id,
                depth,
                column,
                subHeaders: (column.columns ?? []).map((col, i) => createHeader(i, col, depth + 1)),
                colSpan: column.columns?.length ?? 1,
                rowSpan: 1,
                isPlaceholder: false,
            }
        }
        const _headers = columns.map((col, index) => createHeader(index, col))
        const _filters = []
        const _initFilters = {}
        let _hasFilters = false
        getLeafColumns(columns).forEach((column, index) => {
            const { filterElement, filterPlaceholder } = column
            const id = getColumnId(column, String(index))
            const field = column.field ?? ''
            const selector = !!column.selector
            const filter = column.filter ?? false
            if (filter) _initFilters[field] = ''
            if (!_hasFilters) _hasFilters = filter
            _filters.push({
                id,
                className: classNames([column.className, column.filterClassName]),
                style: joinStyles([column.style, column.filterStyle]),
                filter,
                filterElement,
                filterElementClassName: column.filterElementClassName,
                filterElementStyle: column.filterElementStyle,
                filterPlaceholder,
                field,
                selector: selector,
            })
        })
        const mainHeaders = []
        const _headerGroups = Array.from({ length: maxDepth + 1 }, (_, depth) => ({
            id: String(depth),
            depth,
            headers: [],
        }))
        const insertHeaderInGroup = (header) => {
            const subHeaders = (
                header.subHeaders.length === 0 && header.depth < maxDepth
                    ? [
                          {
                              id: `${header.depth + 1}_${header.id}`,
                              depth: header.depth + 1,
                              column: header.column,
                              subHeaders: [],
                              colSpan: 1,
                              rowSpan: 1,
                              isPlaceholder: true,
                          },
                      ]
                    : header.subHeaders
            ).map(insertHeaderInGroup)
            const _header = {
                ...header,
                rowSpan: header.subHeaders.length === 0 ? maxDepth + 1 - header.depth : header.rowSpan,
                subHeaders,
            }
            if (!header.isPlaceholder && header.colSpan === 1) mainHeaders.push(_header)
            _headerGroups[header.depth].headers.push(_header)
            return _header
        }
        _headers.forEach(insertHeaderInGroup)
        const _footerGroups = Array.from({ length: maxDepth + 1 }, (_, depth) => ({
            id: String(depth),
            depth,
            headers: [],
        }))
        const insertFooterInGroup = (header) => {
            let _header = { ...header }
            if (header.subHeaders.length) {
                const subHeaders = (
                    header.subHeaders.length === 0 && header.depth < maxDepth
                        ? [
                              {
                                  id: `${header.depth + 1}_${header.id}`,
                                  depth: header.depth + 1,
                                  column: header.column,
                                  subHeaders: [],
                                  colSpan: 1,
                                  rowSpan: 1,
                                  isPlaceholder: true,
                              },
                          ]
                        : header.subHeaders
                ).map(insertFooterInGroup)
                _header = { ...header, subHeaders }
            } else {
                if (header.depth < maxDepth) {
                    const subHeaders = [{ ...header, depth: header.depth + 1 }].map(insertFooterInGroup)
                    _header = {
                        id: `${header.depth}_${header.id}`,
                        depth: header.depth,
                        column: header.column,
                        subHeaders,
                        colSpan: 1,
                        rowSpan: 1,
                        isPlaceholder: true,
                    }
                }
            }
            _footerGroups[header.depth].headers.push(_header)
            return _header
        }
        _headers.forEach(insertFooterInGroup)
        const cleanedFooterGroups = _footerGroups.reduceRight((carry, footerGroup) => {
            if (footerGroup.headers.some((header) => !header.isPlaceholder && header.column.footer)) {
                carry.push(footerGroup)
            }
            return carry
        }, [])
        return {
            headerGroups: _headerGroups,
            footerGroups: cleanedFooterGroups,
            headers: mainHeaders,
            columnFilters: _filters,
            hasFilters: _hasFilters,
            initFilters: _initFilters,
        }
    }, [columns])
    const pager = useMemo(() => {
        if (!pagination || lazy) {
            return initialPagination
        }
        const ps = pageSize && pageSize > 0 ? pageSize : 10
        const ti = totalItems && totalItems >= 0 ? totalItems : 0
        const cp = currentPage && currentPage > 0 ? currentPage : 1
        const tp = Math.ceil(ti / ps)
        return {
            currentPage: tp > 0 ? cp : 0,
            pageSize: ps,
            totalItems: ti,
            totalPages: tp,
            offset: (cp - 1) * ps,
            limit: ps,
        }
    }, [currentPage, totalItems, pageSize, pagination])
    useEffect(() => {
        setLoading(true)
        updateRows({ sort })
        setLoading(false)
    }, [values, selection, groupRowsBy, pager])
    useEffect(() => {
        if (filterable && !filters) {
            setFilters({ ...initFilters, ...defaultFilters })
        }
    }, [filterable, defaultFilters])
    useEffect(() => {
        if (!searching && wrapperRef.current) {
            const scrollTop = wrapperRef.current.scrollTop
            if (scrollTop > 32) {
                if (wrapperRef.current.scrollHeight - wrapperRef.current.clientHeight === scrollTop) {
                    wrapperRef.current.scrollTop -= 32
                }
            }
        }
    }, [searching])
    const getPaginatedData = (data) => {
        if (!pagination || lazy) {
            return data
        }
        return data.slice(pager.offset, pager.offset + pager.limit)
    }
    const getSortedData = (data, sort) => {
        if (lazy || !sort.field) {
            return data
        }
        return sortObjects(data, [
            {
                key: sort.field,
                order: sort.order ?? undefined,
            },
        ])
    }
    const getFilteredData = (data) => {
        return data
    }
    const getRows = (data) => {
        rowsRef.current = []
        rowsIndexRef.current = {}
        let _allSelected = true
        let selectableIndex = -1
        const _selection = selection ? (Array.isArray(selection) ? selection : [selection]) : []
        const _rows = data.map((value, index) => {
            const id = (rowKeyField ? propFromName(value, rowKeyField) : null) ?? String(index)
            let selectable = false
            let selected = false
            if (selectionMode) {
                selectable = typeof isRowSelectable === 'function' ? isRowSelectable(value) : true
                selected = !!_selection.find((s) => compareData(s, value))
                _allSelected = _allSelected && (!selectable || selected)
                if (selectable) selectableIndex++
            }
            const _value = {
                id,
                data: value,
                rowIndex: index,
                selectable,
                selected,
                disabled: !selectable,
                selectableIndex: selectable ? selectableIndex : -1,
                subRows: [],
            }
            return _value
        })
        return { rows: _rows, allSelected: _allSelected }
    }
    /** Agrupador de filas del DataTable. */
    const groupRows = (_rows, field) => {
        // Agrupar filas
        const groups = _rows.reduce((carry, row) => {
            const key = propFromName(row.data, field)
            if (!carry[key]) carry[key] = []
            carry[key].push(row)
            return carry
        }, {})
        // Generar subheaders para cada grupo de filas
        const groupedRows = []
        let _selectableIndex = 0
        Object.keys(groups).forEach((key) => {
            const subRows = groups[key].map((row) => {
                if (row.selectable) {
                    row.selectableIndex = _selectableIndex
                    _selectableIndex++
                }
                return row
            })
            groupedRows.push({
                id: key,
                data: key,
                rowIndex: -1,
                selectable: false,
                selected: false,
                disabled: false,
                selectableIndex: -1,
                subRows,
            })
            subRows.length && groupedRows.push(...subRows)
        })
        return groupedRows
    }
    const updateRows = (states) => {
        const result = getRows(getPaginatedData(getSortedData(getFilteredData(values), states.sort)))
        setRows(groupRowsBy ? groupRows(result.rows, groupRowsBy) : result.rows)
        setAllSelected(result.allSelected)
    }
    /** Handler que se ejecuta cuando se de/selecciona una fila del DataTable. */
    const handleSelect = (value, selected) => {
        if (typeof onSelectionChange === 'function') {
            let _selection = null
            if (selectionMode === 'multiple') {
                if (Array.isArray(selection)) {
                    _selection = selected ? [...selection.filter((s) => !compareData(s, value))] : [...selection, value]
                } else {
                    _selection = selected ? [] : [value]
                }
            } else {
                _selection = selected ? null : value
            }
            onSelectionChange(_selection)
        }
    }
    /** Handler que se ejecuta cuando el valor del input de de/seleccionar todas las filas del DataTable cambia. */
    const handleToggleSelectAll = () => {
        if (onSelectionChange) {
            const getSelectableRows = (data) => {
                return data.reduce((carry, row) => {
                    if (row.groupRows) {
                        carry.push(...getSelectableRows(row.groupRows))
                    } else {
                        row.selectable && carry.push(row.value)
                    }
                    return carry
                }, [])
            }
            const _selection = allSelected ? [] : getSelectableRows(rows)
            onSelectionChange(_selection)
        }
    }
    /** Handler que se ejecuta cuando el orden de una fila cambia. */
    const handleSort = (field) => {
        const _sort = { ...initialSort }
        if (field === sort.field) {
            if (sort.order !== orders.descOrder) {
                if (sort.order === orders.ascOrder) {
                    // ASC -> DESC
                    _sort.order = orders.descOrder
                } else {
                    // Sin orden -> ASC
                    _sort.order = orders.ascOrder
                }
                _sort.field = field
            }
        } else {
            _sort.order = orders.ascOrder
            _sort.field = field
        }
        setSort(_sort)
        if (lazy) {
            onSort?.(_sort.field, _sort.order)
        } else {
            updateRows({ sort: _sort })
        }
    }
    /** Maneja el evento keydown de las filas seleccionables. */
    const handleRowKeyDown = (e, index) => {
        const upRowKey = (keyboardNavigation?.upRowKey ?? 'ArrowUp').toLowerCase()
        const downRowKey = (keyboardNavigation?.downRowKey ?? 'ArrowDown').toLowerCase()
        const key = e.key.toLowerCase()
        switch (key) {
            case upRowKey: {
                e.preventDefault()
                if (index > 0) {
                    rowsRef.current[index - 1].focus()
                    rowsRef.current[index - 1].scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    })
                }
                break
            }
            case downRowKey: {
                e.preventDefault()
                if (index < rowsRef.current.length - 1) {
                    rowsRef.current[index + 1].focus()
                    rowsRef.current[index + 1].scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    })
                }
                break
            }
            default:
                break
        }
    }
    /** Maneja el evento keydown de las filas seleccionables. */
    const handleRowKeyUp = (e) => {
        const firstRowKey = (keyboardNavigation?.firstRowKey ?? 'Home').toLowerCase()
        const lastRowKey = (keyboardNavigation?.lastRowKey ?? 'End').toLowerCase()
        const key = e.key.toLowerCase()
        switch (key) {
            case firstRowKey: {
                e.preventDefault()
                rowsRef.current[0].focus()
                rowsRef.current[0].scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                })
                break
            }
            case lastRowKey: {
                e.preventDefault()
                rowsRef.current[rowsRef.current.length - 1].focus()
                rowsRef.current[rowsRef.current.length - 1].scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                })
                break
            }
            default:
                break
        }
        onRowKeyUp?.(e)
    }
    /** Limpia el orden a los valores iniciales. */
    const clearSort = () => {
        setSort(initialSort)
        onSort && onSort(initialSort.field, initialSort.order)
    }
    /** Restablece el orden a los valores predeterminados. */
    const resetSort = () => {
        const _sort = {
            field: defaultSortField ?? null,
            order: (defaultSortField && defaultSortOrder) || orders.noOrder,
        }
        setSort(_sort)
        onSort && onSort(_sort.field, _sort.order)
    }
    /** Limpia los filtros a sus valores iniciales. */
    const clearFilters = () => {
        setFilters(initFilters)
        onFilter && onFilter(initFilters)
    }
    /** Restablece los filtros a los valores predeterminados. */
    const resetFilters = () => {
        setFilters({ ...initFilters, ...defaultFilters })
        onFilter && onFilter({ ...initFilters, ...defaultFilters })
    }
    /** Establece el foco en una fila seleccionable. */
    const focusRow = (rowIndex) => {
        if (rowsRef.current.length) {
            const _rowIndex =
                rowIndex < 0 ? 0 : rowIndex > rowsRef.current.length - 1 ? rowsRef.current.length - 1 : rowIndex
            const index = rowsIndexRef.current[_rowIndex]
            if (index >= 0) {
                rowsRef.current[index].focus()
                rowsRef.current[index].scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                })
            }
        }
    }
    const handleWrapperScroll =
        !finalList.current && !pagination && onVirtualScroll
            ? debounce(async () => {
                  if (wrapperRef.current) {
                      const clientHeight = wrapperRef.current.clientHeight
                      const scrollHeight = wrapperRef.current.scrollHeight
                      const scrollTop = wrapperRef.current.scrollTop
                      if (clientHeight + scrollTop === scrollHeight) {
                          if (!searching) {
                              setSearching(true)
                              const numRows = await onVirtualScroll()
                              if (numRows === 0) {
                                  finalList.current = true
                              }
                              setSearching(false)
                          }
                      }
                  }
              }, 50)
            : undefined
    return (
        <div
            {...props}
            className={classNames(
                'modular-datatable',
                typeof responsive === 'string' && `responsive-${responsive}`,
                props.className,
            )}
            style={props.style}
        >
            <div
                ref={wrapperRef}
                onScroll={handleWrapperScroll}
                className={classNames('dt-wrapper', headerSticky && 'dth-sticky', tableWrapperClassName)}
                style={tableWrapperStyle}
            >
                <BaseTable className={tableClassName} style={tableStyle}>
                    <DataTableHeader
                        innerRef={headerRef}
                        headerGroups={headerGroups}
                        defaultAlignHeader={defaultAlignHeader}
                        columnFilters={columnFilters}
                        filterable={filterable && hasFilters}
                        filters={filters}
                        setFilters={setFilters}
                        filterDelay={filterDelay}
                        onFilter={onFilter}
                        sort={sort}
                        onSort={handleSort}
                        selectionMode={selectionMode}
                        allSelected={allSelected}
                        onToggleSelectAll={handleToggleSelectAll}
                        headerElement={headerElement}
                        className={tableHeaderClassName}
                        style={tableHeaderStyle}
                    />
                    <BaseTbody className={tableBodyClassName} style={tableBodyStyle}>
                        {rows.length > 0 ? (
                            rows.map((row, index) =>
                                row.subRows.length ? (
                                    groupRowsMode === 'subheader' ? (
                                        <BaseTr
                                            key={row.id}
                                            className={classNames(
                                                'dtr-subheader',
                                                typeof rowClassName === 'function'
                                                    ? rowClassName(row.data)
                                                    : rowClassName,
                                                subheaderClassName,
                                            )}
                                            style={joinStyles(
                                                headerSticky &&
                                                    headerRef.current && { top: `${headerRef.current.offsetHeight}px` },
                                                typeof rowStyle === 'function' ? rowStyle(row.data) : rowStyle,
                                                subheaderStyle,
                                            )}
                                        >
                                            <BaseTd colSpan={headers.length}>
                                                {subheaderTemplate ? subheaderTemplate(row.data) : row.data}
                                            </BaseTd>
                                        </BaseTr>
                                    ) : null
                                ) : (
                                    <DataTableRow
                                        key={row.id}
                                        innerRef={(el) => {
                                            if (el && row.selectable) {
                                                rowsRef.current[row.selectableIndex] = el
                                                rowsIndexRef.current[row.rowIndex] = row.selectableIndex
                                            }
                                        }}
                                        {...row}
                                        selectionMode={selectionMode}
                                        selectionOn={selectionOn}
                                        onKeyDown={handleRowKeyDown}
                                        onKeyUp={handleRowKeyUp}
                                        onSelect={handleSelect}
                                        headers={headers}
                                        rowSpanField={groupRowsBy}
                                        rowSpan={groupRowsMode === 'rowspan' ? rows[index - 1].subRows.length : 1}
                                        className={
                                            typeof rowClassName === 'function' ? rowClassName(row.data) : rowClassName
                                        }
                                        style={typeof rowStyle === 'function' ? rowStyle(row.data) : rowStyle}
                                    />
                                ),
                            )
                        ) : (
                            <BaseTr>
                                <BaseTd colSpan={headers.length}>
                                    {emptyMessage || <div className='dtr-msg-on-empty'>Sin registros</div>}
                                </BaseTd>
                            </BaseTr>
                        )}
                        {searching &&
                            new Array(skeletonRows).fill(0).map((_, index) => (
                                <BaseTr key={index}>
                                    {headers.map(({ column: col }, i) => (
                                        <BaseTd key={i}>
                                            {col.selector && selectionMode ? (
                                                <div className='dtr-selector'>
                                                    <div
                                                        className={classNames(
                                                            selectionMode === 'multiple'
                                                                ? 'dtr-check-skeleton'
                                                                : 'dtr-radio-skeleton',
                                                            col.skeletonClassName,
                                                        )}
                                                        style={col.skeletonStyle}
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    className={classNames('dtr-cell-skeleton', col.skeletonClassName)}
                                                    style={col.skeletonStyle}
                                                />
                                            )}
                                        </BaseTd>
                                    ))}
                                </BaseTr>
                            ))}
                    </BaseTbody>
                    {footerElement ??
                        (!!footerGroups.length && (
                            <DataTableFooter
                                footerGroups={footerGroups}
                                values={values}
                                className={tableFooterClassName}
                                style={tableFooterStyle}
                            />
                        ))}
                </BaseTable>
                <DataTableRef
                    ref={innerRef}
                    clearFilters={clearFilters}
                    resetFilters={resetFilters}
                    clearSort={clearSort}
                    resetSort={resetSort}
                    focusRow={focusRow}
                />
            </div>
            {pagination && (
                <Pagination
                    currentPage={currentPage}
                    totalItems={lazy ? totalItems : values.length}
                    pageSize={pageSize ?? 10}
                    pageSizeOptions={pageSizeOptions}
                    onPageChange={onPageChange}
                    onPageSizeChange={onPageSizeChange}
                    className={paginationClassName}
                    style={paginationStyle}
                    responsive={responsive}
                    pagesToShow={pagesToShow}
                    extraOptions={
                        selectionMode && (
                            <span style={{ fontWeight: 500, color: '#71717A' }}>
                                {Array.isArray(selection) ? selection.length : selection ? 1 : 0} de{' '}
                                {lazy ? totalItems : values.length} filas seleccionadas
                            </span>
                        )
                    }
                />
            )}
        </div>
    )
}
