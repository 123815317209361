import { forwardRef, useMemo } from 'react'
import { BaseInput } from '../base/BaseInput'
const InputFunction = ({ readOnly, maxFractionDigits, type = 'text', showNumberControls = true, ...props }, ref) => {
    const { typeInput, isNumeric, isDecimal } = useMemo(() => {
        const _isDecimal = ['decimal', 'currency'].includes(type)
        const _isNumeric = _isDecimal || ['integer', 'number'].includes(type)
        const _typeInput = type === 'number' ? (showNumberControls ? type : 'text') : _isNumeric ? 'text' : type
        return { typeInput: _typeInput, isNumeric: _isNumeric, isDecimal: _isDecimal }
    }, [type, showNumberControls])
    const handleKeyDown = (e) => {
        const regex = isDecimal ? /[0-9,.]/ : /[0-9]/
        // Si ctrl no esta presionado y la tecla es un carácter.
        if (!e.ctrlKey && e.key.length === 1) {
            // Si no pasa el regex o si es decimal, la tecla es punto o coma y el valor actual del input ya tiene un punto.
            if (
                !regex.test(e.key) ||
                (isDecimal && ['.', ','].includes(e.key) && e.currentTarget.value.includes('.'))
            ) {
                e.preventDefault()
            }
        }
        props.onKeyDown && props.onKeyDown(e)
    }
    const handleChange = (e) => {
        const v = e.target.value
        const input = e.target
        if (v.length > 0 && !verifyNumberFormat(v)) {
            const cursorPosition = input.selectionStart || 0
            e.target.value = formatNumber(v)
            // Restablecer la posición del cursor
            requestAnimationFrame(() => {
                input.selectionStart = input.selectionEnd = cursorPosition + (e.target.value.length - v.length)
            })
        }
        props.onChange && props.onChange(e)
    }
    const formatNumber = (v) => {
        // limpiar caracteres no numéricos y diferentes de punto o coma
        let formattedValue = v.replace(/[^0-9,.]/g, '')
        // convertir comas en puntos
        formattedValue = formattedValue.replace(/,/g, '.')
        const valueParts = formattedValue.split('.')
        formattedValue = valueParts[0]
        if (isDecimal && valueParts.length > 1) {
            let decimalPart =
                type === 'currency'
                    ? valueParts[1].slice(0, 2)
                    : (maxFractionDigits || 0) > 0
                      ? valueParts[1].slice(0, maxFractionDigits)
                      : valueParts[1]
            formattedValue = `${formattedValue}.${decimalPart}`
        }
        return formattedValue
    }
    /** Obtiene el regex  */
    const getNumberFormatRegex = () => {
        const maxfd = (maxFractionDigits || 0) > 0 ? maxFractionDigits : ''
        const decimalRegex = new RegExp(`^\\d*([.]{0,1}[0-9]{0,${maxfd}})?$`)
        const currencyRegex = /^\d*([.]{0,1}\d{0,2})?$/
        const numberRegex = /^\d+$/
        const regex = type === 'decimal' ? decimalRegex : type === 'currency' ? currencyRegex : numberRegex
        return regex
    }
    /** Verifica si un valor tiene el formato numérico correspondiente. */
    const verifyNumberFormat = (v) => {
        return getNumberFormatRegex().test(v)
    }
    return (
        <BaseInput
            ref={ref}
            {...props}
            {...(isNumeric && {
                onChange: handleChange,
                onKeyDown: handleKeyDown,
            })}
            type={typeInput}
        />
    )
}
export const Input = forwardRef(InputFunction)
