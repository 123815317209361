import Header from 'Component/Header'
import { objectToQueryString } from 'hooks/useFilters'
import useHttpToast from 'hooks/useHttpToast'
import useReporteValorados from 'hooks/useReporteValorados'
import { Button } from 'modules/modular/Button'
import { Checkbox } from 'modules/modular/Checkbox'
import { FieldGroup } from 'modules/modular/FieldGroup'
import { Input } from 'modules/modular/Input'
import { Select } from 'modules/modular/Select'
import { classNames, cleanObject, empty } from 'modules/modular/utils'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import RecaudacionesServices from 'services/recaudaciones.service'
import { formatDate, pick } from 'utils'
import { verifyAccess } from 'utils/verifyAccess'

// Todos los roles con acceso a la página de reportes
const allRoles = [
    'AdministradorRecaudaciones',
    'ReportesRecaudaciones',
    'Fiscalizador',
    'ReportesRecaudacionesDA',
    'JefeCajerosValorados',
    'JefeCajerosCajaCentral',
    'CajeroValorados',
    'CajeroCajaCentral',
    'dtic',
]

const reporteOptions = [
    {
        label: 'Informe Consolidado por Unidad y Cuenta',
        value: 'ICpUyC',
        required: allRoles,
        operator: 'OR',
    },
    {
        label: 'Informe Consolidado por Concepto',
        value: 'ICpC',
        required: allRoles,
        operator: 'OR',
    },
    {
        label: 'Informe Consolidado por Unidad',
        value: 'ICpU',
        required: allRoles,
        operator: 'OR',
    },
    {
        label: 'Informe Consolidado Institucional',
        value: 'ICI',
        required: ['AdministradorRecaudaciones', 'ReportesRecaudaciones'],
        operator: 'OR',
    },
    {
        label: 'Informe Cobro Comisión Bancaria',
        value: 'ICCB',
        required: ['AdministradorRecaudaciones', 'ReportesRecaudaciones', 'dtic'],
        operator: 'OR',
    },
    {
        label: 'Informe Distribución Aportes',
        value: 'IDA',
        required: ['AdministradorRecaudaciones', 'ReportesRecaudaciones'],
        operator: 'OR',
    },
    {
        label: 'Detalle de Recaudaciones según Recibos',
        value: 'DRsR',
        required: allRoles,
        operator: 'OR',
    },
    {
        label: 'Detalle de Recaudaciones Utis',
        value: 'DRpC',
        required: allRoles,
        operator: 'OR',
    },
    {
        label: 'Detalle de Recaudaciones Tipos de Estudiante',
        value: 'DRTE',
        required: allRoles,
        operator: 'OR',
    },
    {
        label: 'Anulados',
        value: 'RA', // Recibos Anulados
        required: allRoles,
        operator: 'OR',
    },
    {
        label: 'Valores',
        value: 'Valores',
        required: ['JefeCajerosCajaCentral', 'CajeroCajaCentral'],
        operator: 'OR',
    },
    {
        label: 'Informe Resumen Recibos',
        value: 'IRR',
        required: allRoles,
        operator: 'OR',
    },
]
const currentDate = formatDate(new Date())
const initialFilters = {
    caja: false,
    electronico: false,
    estructura_id: '',
    usuario_id: '',
    fecha_inicio: currentDate,
    fecha_fin: currentDate,
    rubro_id: '',
    unidad_id: '',
    concepto_id: '',
    cuenta_id: '',
}

export default function ReporteValorados() {
    const { showHttpToast } = useHttpToast()
    const reporteValorados = useReporteValorados()
    const lastEstructura = useRef('') // Almacena la ultima estructura seleccionada antes que los filtros avanzados se oculten.
    const initAdvancedFilters = useRef(false) // Especifica si los filtros avanzados ya se mostraron por lo menos una vez.
    const { user } = useSelector((state) => state.auth)
    const [selectedReporte, setSelectedReporte] = useState('ICpUyC')
    const [filters, setFilters] = useState(initialFilters)
    const [showAdvancedFilters, setShowAdvancedFilters] = useState(false)
    const [excelFileName, setExcelFileName] = useState('')
    const [isEnable, setIsEnable] = useState({ fieldEstructura: false, fieldUsuario: false, advancedFilters: true })
    const [isLoading, setIsLoading] = useState({ buttonPdf: false, buttonExcel: false })
    const [errors, setErrors] = useState({})

    const [estructuras, setEstructuras] = useState([])
    const [usuarios, setUsuarios] = useState([])
    const [rubros, setRubros] = useState([])
    const [unidades, setUnidades] = useState([])
    const [conceptos, setConceptos] = useState([])
    const [cuentas, setCuentas] = useState([])

    useEffect(() => {
        loadEstructuras()
        loadUsuarios()

        // Admin puede filtrar por estructura, usuario y demás
        const isAdmin = verifyAccess(['AdministradorRecaudaciones', 'ReportesRecaudaciones'], 'OR')
        // Report puede filtrar por usuario y demás
        const isReport = verifyAccess(
            ['Fiscalizador', 'ReportesRecaudacionesDA', 'JefeCajerosValorados', 'JefeCajerosCajaCentral'],
            'OR',
        )
        setIsEnable((prev) => ({ ...prev, fieldEstructura: isAdmin, fieldUsuario: isAdmin || isReport }))
        // Filtros iniciales según el rol
        const preFilters = isAdmin
            ? null
            : isReport
            ? { estructura_id: user.estructura }
            : { estructura_id: user.estructura, usuario_id: user.id }
        if (preFilters) {
            setFilters((prev) => ({ ...prev, ...preFilters }))
            lastEstructura.current = preFilters.estructura_id
        }
    }, [user])

    /** Carga la lista de estructuras para los filtros. */
    const loadEstructuras = async () => {
        const { status, data } = await RecaudacionesServices.reporteValorados.listEstructuras()
        if (status === 200) {
            setEstructuras(data.data)
        } else {
            estructuras.length && setEstructuras([])
        }
    }
    /**
     * Carga la lista de usuarios para los filtros.
     * @param {number | null} estructura_id ID de estructura.
     */
    const loadUsuarios = async (estructura_id = null) => {
        const params = estructura_id ? objectToQueryString({ estructura_id }) : {}
        const { status, data } = await RecaudacionesServices.reporteValorados.listUsuarios(params)
        if (status === 200) {
            setUsuarios(data.data)
        } else {
            usuarios.length && setUsuarios([])
        }
    }
    /** Carga la lista de rubros para los filtros. */
    const loadRubros = async () => {
        const { status, data } = await RecaudacionesServices.reporteValorados.listRubros({
            sort: 'descripcion',
            all: 1,
        })
        if (status === 200) {
            setRubros(data.data)
        } else {
            rubros.length && setRubros([])
        }
    }
    /**
     * Carga la lista de unidades para los filtros.
     * @param {number | null} estructura_id ID de estructura.
     */
    const loadUnidades = async (estructura_id = null) => {
        const params = estructura_id ? objectToQueryString({ estructura_id }) : {}
        const { status, data } = await RecaudacionesServices.reporteValorados.listUnidades(params)
        if (status === 200) {
            setUnidades(data.data)
        } else {
            unidades.length && setUnidades([])
        }
    }
    /**
     * Carga la lista de rubros para los filtros.
     * @param {number | null} rubro_id ID de rubro.
     */
    const loadConceptos = async (rubro_id = null) => {
        const params = rubro_id ? objectToQueryString({ rubro_id }) : {}
        const { status, data } = await RecaudacionesServices.reporteValorados.listConceptos(params)
        if (status === 200) {
            setConceptos(data.data)
        } else {
            conceptos.length && setConceptos([])
        }
    }
    /**
     * Carga la lista de cuentas para los filtros.
     * @param {object | null} additional Filtros de búsqueda, acepta los siguientes filtros:
     * - estructura_id {number}: ID de estructura.
     * - unidad_id {number}: ID de unidad.
     * - concepto_id {number}: ID de concepto.
     * Si es null obtiene todas las cuentas.
     */
    const loadCuentas = async (additional = null) => {
        const params = additional ? objectToQueryString(cleanObject(additional)) : {}
        const { status, data } = await RecaudacionesServices.reporteValorados.listCuentas(params)
        if (status === 200) {
            setCuentas(data.data)
        } else {
            cuentas.length && setCuentas([])
        }
    }

    const handleSelectReporte = (e) => {
        setSelectedReporte(e.target.value)
        if (['ICI', 'ICCB', 'IDA'].includes(e.target.value)) {
            showAdvancedFilters && setShowAdvancedFilters(false)
            isEnable.advancedFilters && setIsEnable((prev) => ({ ...prev, advancedFilters: false }))
        } else {
            !isEnable.advancedFilters && setIsEnable((prev) => ({ ...prev, advancedFilters: true }))
        }
    }
    const handleChangeFilters = (e) => {
        const { name, value } = e.target
        if (name === 'estructura_id') {
            setFilters((prev) => ({ ...prev, [name]: value, usuario_id: '', unidad_id: '', cuenta_id: '' }))
            loadUsuarios(value)
            if (showAdvancedFilters) {
                loadUnidades(value)
                loadCuentas({ ...pick(filters, ['unidad_id', 'concepto_id']), estructura_id: value })
            }
        } else if (['unidad_id', 'concepto_id'].includes(name)) {
            setFilters((prev) => ({ ...prev, [name]: value, cuenta_id: '' }))
            loadCuentas({ ...pick(filters, ['estructura_id', 'unidad_id', 'concepto_id']), [name]: value })
        } else if (name === 'rubro_id') {
            setFilters((prev) => ({ ...prev, [name]: value, concepto_id: '' }))
            loadConceptos(value)
        } else {
            setFilters((prev) => ({ ...prev, [name]: value }))
        }
    }
    const handleChangeAdvancedFilters = (e) => {
        setShowAdvancedFilters(e.target.checked)
        if (e.target.checked) {
            /* 
                La primera vez que se muestre los filtros avanzados o si la estructura seleccionada cambio
                cargar unidades y cuentas
            */
            if (!initAdvancedFilters.current || filters.estructura_id !== lastEstructura.current) {
                lastEstructura.current = filters.estructura_id
                loadUnidades(filters.estructura_id)
                loadCuentas(pick(filters, ['estructura_id', 'unidad_id', 'concepto_id']))
            }
            // La primera vez que se muestre los filtros avanzados cargar rubros y conceptos
            if (!initAdvancedFilters.current) {
                loadRubros()
                loadConceptos()
                initAdvancedFilters.current = true
            }
        }
    }

    const validateFilters = (values) => {
        const _errors = {}
        if (!values.fecha_inicio) _errors.fecha_inicio = 'Requerido'
        if (!values.fecha_fin) _errors.fecha_fin = 'Requerido'
        if (values.fecha_inicio && values.fecha_fin) {
            if (values.fecha_fin < values.fecha_inicio) {
                _errors.fecha_inicio = 'Fecha invalida'
                _errors.fecha_fin = 'Fecha invalida'
            }
        }
        return empty(_errors) ? null : _errors
    }

    const handleGeneratePdf = async () => {
        setIsLoading((prev) => ({ ...prev, buttonPdf: true }))
        const _errors = validateFilters(filters)
        if (_errors) {
            setErrors(_errors)
        } else {
            const estructura = filters.estructura_id
                ? estructuras.find((e) => String(e.id) === filters.estructura_id)
                : null
            const usuario = filters.usuario_id ? usuarios.find((u) => String(u.id) === filters.usuario_id) : null
            const rubro = filters.rubro_id ? rubros.find((r) => String(r.id) === filters.rubro_id) : null
            const unidad = filters.unidad_id ? unidades.find((u) => String(u.id) === filters.unidad_id) : null
            const concepto = filters.concepto_id ? conceptos.find((c) => String(c.id) === filters.concepto_id) : null
            const cuenta = filters.cuenta_id ? cuentas.find((c) => String(c.id) === filters.cuenta_id) : null
            const reportParams = {
                usuario: user.name,
                unidad: user.unidad,
                isAdmin: isEnable.fieldEstructura,
                modePrint: false,
                showAdvancedFilters,
                filters: {
                    fechaInicio: filters.fecha_inicio.split('-').reverse().join('-'),
                    fechaFin: filters.fecha_fin.split('-').reverse().join('-'),
                    electronico: filters.electronico,
                    caja: filters.caja,
                    usuario: usuario ? `${usuario.apellidos} ${usuario.nombres}` : 'Todos',
                    estructura: estructura?.descripcion ?? 'Todos',
                    rubro: rubro?.descripcion ?? 'Todos',
                    unidad: unidad?.descripcion ?? 'Todos',
                    concepto: concepto?.descripcion ?? 'Todos',
                    cuenta: cuenta?.nombre ?? 'Todos',
                },
            }
            try {
                switch (selectedReporte) {
                    case 'ICpUyC':
                        await reporteValorados.consolidado.porUnidadCuenta(filters, {
                            reportParams,
                            diezmo: estructura?.diezmo,
                        })
                        break
                    case 'ICpC':
                        await reporteValorados.consolidado.porConcepto(filters, { reportParams })
                        break
                    case 'ICpU':
                        await reporteValorados.consolidado.porUnidad(filters, { reportParams })
                        break
                    case 'ICI':
                        await reporteValorados.consolidado.institucional(filters, { reportParams })
                        break
                    case 'ICCB':
                        await reporteValorados.cobroComisionBancaria(filters, { reportParams })
                        break
                    case 'IDA':
                        await reporteValorados.distribucionAportes(filters, { reportParams })
                        break
                    case 'DRsR':
                        await reporteValorados.recaudaciones.segunRecibos(filters, { reportParams })
                        break
                    case 'DRpC':
                        await reporteValorados.recaudaciones.porCuenta(filters, { reportParams })
                        break
                    case 'DRTE':
                        await reporteValorados.recaudaciones.porTipoEstudiante(filters, { reportParams })
                        break
                    case 'RA':
                        await reporteValorados.recibosAnulados(filters, { reportParams })
                        break
                    case 'Valores':
                        await reporteValorados.valores(filters, { reportParams })
                        break
                    case 'IRR':
                        await reporteValorados.resumenRecibos(filters, { reportParams })
                        break

                    default:
                        break
                }
            } catch (error) {
                showHttpToast(500, { detail: 'Ocurrió un error al generar el PDF' })
            }
        }
        setIsLoading((prev) => ({ ...prev, buttonPdf: false }))
    }

    const handleGenerateExcel = async () => {
        setIsLoading((prev) => ({ ...prev, buttonPdf: true }))
        const _errors = validateFilters(filters)
        if (_errors) {
            setErrors(_errors)
        } else {
            const estructura = filters.estructura_id
                ? estructuras.find((e) => String(e.id) === filters.estructura_id)
                : null
            const usuario = filters.usuario_id ? usuarios.find((u) => String(u.id) === filters.usuario_id) : null
            const rp = {
                filters: {
                    usuario: usuario ? `${usuario.apellidos} ${usuario.nombres}` : 'Todos',
                    fechaInicio: filters.fecha_inicio.split('-').reverse().join('-'),
                    fechaFin: filters.fecha_fin.split('-').reverse().join('-'),
                },
            }
            const reportParams = [rp.filters.usuario, rp.filters.fechaInicio, rp.filters.fechaFin]
            try {
                switch (selectedReporte) {
                    case 'ICpUyC':
                        await reporteValorados.consolidado.porUnidadCuenta(filters, {
                            type: 'excel',
                            reportParams,
                            diezmo: estructura?.diezmo,
                            fileName: excelFileName,
                        })
                        break
                    case 'ICpC':
                        await reporteValorados.consolidado.porConcepto(filters, {
                            type: 'excel',
                            reportParams,
                            fileName: excelFileName,
                        })
                        break
                    case 'ICpU':
                        await reporteValorados.consolidado.porUnidad(filters, {
                            type: 'excel',
                            reportParams,
                            fileName: excelFileName,
                        })
                        break
                    case 'DRsR':
                        await reporteValorados.recaudaciones.segunRecibos(filters, {
                            type: 'excel',
                            reportParams,
                            fileName: excelFileName,
                        })
                        break
                    case 'DRpC':
                        await reporteValorados.recaudaciones.porCuenta(filters, {
                            type: 'excel',
                            reportParams,
                            fileName: excelFileName,
                        })
                        break
                    case 'DRTE':
                        await reporteValorados.recaudaciones.porTipoEstudiante(filters, {
                            type: 'excel',
                            reportParams,
                            fileName: excelFileName,
                        })
                        break
                    case 'RA':
                        await reporteValorados.recibosAnulados(filters, {
                            type: 'excel',
                            reportParams,
                            fileName: excelFileName,
                        })
                        break
                    case 'Valores':
                        await reporteValorados.valores(filters, {
                            type: 'excel',
                            reportParams,
                            fileName: excelFileName,
                        })
                        break

                    default:
                        break
                }
            } catch (error) {
                showHttpToast(500, { detail: 'Ocurrió un error al generar el Excel' })
            }
        }
        setIsLoading((prev) => ({ ...prev, buttonPdf: false }))
    }

    return (
        <div>
            <Header title='REPORTE DE MOVIMIENTOS' />
            <div className='d-flex justify-content-center'>
                <div className='container shadow-sm pt-5 p-4 bg-body rounded'>
                    <div className='d-flex flex-column align-items-center'>
                        <div className='w-75'>
                            <div
                                className='position-relative rounded mb-5'
                                style={{ border: '1px solid #ccc', padding: '2.5rem 3rem 1.5rem 3rem' }}
                            >
                                <span
                                    className='fw-medium fs-5 bg-white px-4 py-1 position-absolute'
                                    style={{ top: '-1.25rem', left: '1.5rem' }}
                                >
                                    Escoja el Tipo de Reporte
                                </span>
                                <div className='d-flex gap-3'>
                                    <label className='d-flex gap-2 align-items-center'>
                                        <Checkbox
                                            name='electronico'
                                            value={filters.electronico}
                                            onChange={handleChangeFilters}
                                            style={{ fontSize: '1.25rem' }}
                                        />{' '}
                                        <span style={{ userSelect: 'none' }}>Reporte de Cobros Electrónicos</span>
                                    </label>
                                    <label className='d-flex gap-2 align-items-center'>
                                        <Checkbox
                                            name='caja'
                                            value={filters.caja}
                                            onChange={handleChangeFilters}
                                            style={{ fontSize: '1.25rem' }}
                                        />{' '}
                                        <span style={{ userSelect: 'none' }}>Reporte de Cobros en Caja Efectivo</span>
                                    </label>
                                </div>
                                <hr />
                                {reporteOptions
                                    .filter(
                                        (option) =>
                                            empty(option.required) || verifyAccess(option.required, option.operator),
                                    )
                                    .map((option) => (
                                        <div key={option.value}>
                                            <label className='d-inline-flex gap-2 align-items-center mb-1 select-none'>
                                                <input
                                                    type='radio'
                                                    name='reporte'
                                                    value={option.value}
                                                    checked={selectedReporte === option.value}
                                                    onChange={handleSelectReporte}
                                                    className='form-check-input m-0'
                                                    style={{ height: '1.25rem', width: '1.25rem' }}
                                                />
                                                {option.label}
                                            </label>
                                        </div>
                                    ))}
                            </div>
                            <div className='row g-1 mb-3'>
                                <FieldGroup
                                    label={
                                        <div className='text-end' style={{ width: '12rem' }}>
                                            Dirección Administrativa:
                                        </div>
                                    }
                                    className='col-12 mb-0'
                                    inline
                                >
                                    <Select
                                        options={estructuras}
                                        optionValue='id'
                                        optionTemplate={(option) => `${option.codigo} ${option.descripcion}`}
                                        name='estructura_id'
                                        value={filters.estructura_id}
                                        onChange={handleChangeFilters}
                                        placeholder='Todos'
                                        className='form-select-sm'
                                        disabled={!isEnable.fieldEstructura}
                                    />
                                </FieldGroup>
                                <FieldGroup
                                    label={
                                        <div className='text-end' style={{ width: '12rem' }}>
                                            Usuario:
                                        </div>
                                    }
                                    className='col-12 mb-0'
                                    inline
                                >
                                    <Select
                                        options={usuarios}
                                        optionValue='id'
                                        optionTemplate={(option) =>
                                            `${option.apellidos} ${option.nombres}${
                                                option.electronico ? ' - ' + option.estructura : ''
                                            }`
                                        }
                                        name='usuario_id'
                                        value={filters.usuario_id}
                                        onChange={handleChangeFilters}
                                        placeholder='Todos'
                                        className='form-select-sm'
                                        disabled={!isEnable.fieldUsuario}
                                    />
                                </FieldGroup>
                                <FieldGroup
                                    label={
                                        <div className='text-end' style={{ width: '12rem' }}>
                                            Fecha desde:
                                        </div>
                                    }
                                    className='col-6 mb-0'
                                    inline
                                >
                                    <Input
                                        type='date'
                                        name='fecha_inicio'
                                        value={filters.fecha_inicio}
                                        onChange={handleChangeFilters}
                                        className='form-control-sm'
                                        isInvalid={!!errors.fecha_inicio}
                                    />
                                </FieldGroup>
                                <FieldGroup
                                    label={
                                        <div className='text-end' style={{ width: '12rem' }}>
                                            Fecha hasta:
                                        </div>
                                    }
                                    className='col-6 mb-0'
                                    inline
                                >
                                    <Input
                                        type='date'
                                        name='fecha_fin'
                                        value={filters.fecha_fin}
                                        onChange={handleChangeFilters}
                                        className='form-control-sm'
                                        isInvalid={!!errors.fecha_fin}
                                    />
                                </FieldGroup>
                            </div>
                            <label
                                className='d-flex gap-2 align-items-center p-1 mb-3 rounded'
                                style={{ backgroundColor: '#ccc', userSelect: 'none' }}
                            >
                                <input
                                    type='checkbox'
                                    className='form-check-input mt-0'
                                    style={{ height: '1.25rem', width: '1.25rem' }}
                                    checked={showAdvancedFilters}
                                    onChange={handleChangeAdvancedFilters}
                                    disabled={!isEnable.advancedFilters}
                                />
                                <span className={isEnable.advancedFilters ? undefined : 'text-secondary'}>
                                    Filtros de Reporte Avanzado
                                </span>
                            </label>
                            <div className={classNames('collapse mb-5', showAdvancedFilters && 'show')}>
                                <FieldGroup
                                    label={
                                        <div className='text-end' style={{ width: '12rem' }}>
                                            Rubro:
                                        </div>
                                    }
                                    className='mb-1'
                                    inline
                                >
                                    <Select
                                        options={rubros}
                                        optionValue='id'
                                        optionTemplate={(option) => `${option.descripcion} - ${option.codigo}`}
                                        name='rubro_id'
                                        value={filters.rubro_id}
                                        onChange={handleChangeFilters}
                                        placeholder='Todos'
                                        className='form-select-sm'
                                    />
                                </FieldGroup>
                                <FieldGroup
                                    label={
                                        <div className='text-end' style={{ width: '12rem' }}>
                                            Concepto:
                                        </div>
                                    }
                                    className='mb-1'
                                    inline
                                >
                                    <Select
                                        options={conceptos}
                                        optionValue='id'
                                        optionTemplate={(option) => `${option.descripcion} - ${option.codigo}`}
                                        name='concepto_id'
                                        value={filters.concepto_id}
                                        onChange={handleChangeFilters}
                                        placeholder='Todos'
                                        className='form-select-sm'
                                    />
                                </FieldGroup>
                                <FieldGroup
                                    label={
                                        <div className='text-end' style={{ width: '12rem' }}>
                                            Unidad:
                                        </div>
                                    }
                                    className='mb-1'
                                    inline
                                >
                                    <Select
                                        options={unidades}
                                        optionValue='id'
                                        optionTemplate={(option) => `${option.descripcion} - ${option.codigo}`}
                                        name='unidad_id'
                                        value={filters.unidad_id}
                                        onChange={handleChangeFilters}
                                        placeholder='Todos'
                                        className='form-select-sm'
                                    />
                                </FieldGroup>
                                <FieldGroup
                                    label={
                                        <div className='text-end' style={{ width: '12rem' }}>
                                            Cuenta:
                                        </div>
                                    }
                                    className='mb-1'
                                    inline
                                >
                                    <Select
                                        options={cuentas}
                                        optionValue='id'
                                        optionTemplate={(option) => `${option.nombre} - ${option.codigo}`}
                                        name='cuenta_id'
                                        value={filters.cuenta_id}
                                        onChange={handleChangeFilters}
                                        placeholder='Todos'
                                        className='form-select-sm'
                                    />
                                </FieldGroup>
                                <div className='row'>
                                    <FieldGroup
                                        label={
                                            <div className='text-end' style={{ width: '12rem' }}>
                                                Nombre del archivo:
                                            </div>
                                        }
                                        className='col-7 mb-0'
                                        inline
                                    >
                                        <Input
                                            value={excelFileName}
                                            onChange={(e) => setExcelFileName(e.target.value)}
                                            className='form-control-sm'
                                        />
                                    </FieldGroup>
                                    <div className='col-5'>
                                        Dirección por defecto C:\Descargas\fechaActual_Report.xlsx
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='text-center bg-blue rounded py-3'>
                        <Button
                            onClick={handleGeneratePdf}
                            startIcon={<span className='bi-printer' />}
                            loading={isLoading.buttonPdf}
                            disabled={isLoading.buttonExcel}
                        >
                            IMPRIMIR
                        </Button>
                        {showAdvancedFilters && (
                            <Button
                                variant='success'
                                onClick={handleGenerateExcel}
                                loading={isLoading.buttonExcel}
                                disabled={isLoading.buttonPdf || selectedReporte === 'IRR'}
                                className='ms-3'
                            >
                                EXPORTAR A EXCEL
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
