import { empty } from 'modules/modular/utils'

export function validateCuenta(values) {
    const errors = {}
    if (!values.codigo.trim()) errors.codigo = 'Required'
    if (!values.id) {
        errors.nombre = 'Required'
        errors.unidad = 'Required'
    }
    if (!(values.precio > 0)) errors.precio = 'Debe ser mayor a cero'
    if (!(values.cantidad > 0)) errors.cantidad = 'Debe ser mayor a cero'
    return empty(errors) ? null : errors
}
