import { classNames, joinStyles, propFromName } from '../utils'
import { BaseDataTableCheckbox, BaseDataTableRadio, BaseTd, BaseTr } from '../base/BaseDataTable'
export default function DataTableRow({
    innerRef,
    headers,
    selectionMode,
    selectionOn,
    onKeyDown,
    onKeyUp,
    data,
    rowIndex,
    rowSpan,
    rowSpanField,
    selectable,
    disabled,
    selected,
    selectableIndex,
    onSelect,
    className,
    style,
}) {
    const handleSelect = () => {
        onSelect(data, selected)
    }
    const handleEnter = (e) => {
        e.key === 'Enter' && handleSelect()
        onKeyUp?.(e)
    }
    return (
        <BaseTr
            ref={selectionOn === 'row' ? innerRef : undefined}
            className={classNames(
                !!selectionMode && {
                    'dtr-selectable': selectable && selectionOn === 'row',
                    'dtr-selected': selectable && selected,
                    'dtr-disabled': disabled,
                },
                className,
            )}
            style={style}
            {...(selectable &&
                selectionOn === 'row' && {
                    tabIndex: 0,
                    onClick: handleSelect,
                    onKeyUp: handleEnter,
                    onKeyDown: (e) => onKeyDown?.(e, selectableIndex),
                })}
        >
            {headers.map(({ column: col }, index) => {
                const field = col.field ?? ''
                if (rowSpanField && rowSpanField === field && rowSpan === 0) {
                    return null
                }
                const value = propFromName(data, field)
                return (
                    <BaseTd
                        key={col.selector ? 'selector' : (col.id ?? index)}
                        className={classNames(
                            col.className,
                            typeof col.cellClassName === 'function'
                                ? col.cellClassName({ data, value, rowIndex, disabledRow: disabled })
                                : col.cellClassName,
                        )}
                        style={joinStyles(
                            col.style,
                            typeof col.cellStyle === 'function'
                                ? col.cellStyle({ data, value, rowIndex, disabledRow: disabled })
                                : col.cellStyle,
                        )}
                        {...(rowSpan > 1 && rowSpanField === field && { rowSpan })}
                    >
                        {col.selector ? (
                            <div className='dtr-selector'>
                                {selectionMode === 'multiple' ? (
                                    <BaseDataTableCheckbox
                                        ref={selectionOn !== 'row' ? innerRef : undefined}
                                        disabled={disabled}
                                        checked={selected}
                                        {...(disabled || selectionOn === 'row'
                                            ? { tabIndex: -1, onChange: () => {} }
                                            : { onChange: handleSelect, onKeyUp: handleEnter })}
                                    />
                                ) : selectionMode === 'single' ? (
                                    <BaseDataTableRadio
                                        ref={selectionOn !== 'row' ? innerRef : undefined}
                                        disabled={disabled}
                                        checked={selected}
                                        {...(disabled || selectionOn === 'row'
                                            ? { tabIndex: -1, onChange: () => {} }
                                            : { onChange: handleSelect, onKeyUp: handleEnter })}
                                    />
                                ) : null}
                            </div>
                        ) : typeof col.cell === 'function' ? (
                            col.cell({ data, value, rowIndex, disabledRow: disabled })
                        ) : col.cell ? (
                            col.cell
                        ) : !['', null, undefined].includes(value) ? (
                            value
                        ) : typeof col.default === 'function' ? (
                            col.default({ data, value, rowIndex, disabledRow: disabled })
                        ) : (
                            (col.default ?? '')
                        )}
                    </BaseTd>
                )
            })}
        </BaseTr>
    )
}
