/**
 * Elimina propiedades de un objeto por valor o clave.
 * @param obj Objecto a limpiar.
 * @param remove Propiedades a eliminar por valor o clave. Si no se pasa remove por defecto se elimina props con valor undefined.
 * @param remove.values Valores a eliminar.
 * @param remove.keys Claves a eliminar.
 * @returns Objeto limpio.
 */
export function cleanObject(obj, remove) {
    const keys = remove?.keys || []
    const values = remove?.values || [undefined, null, '']
    return Object.fromEntries(
        Object.entries(obj).filter((prop) => {
            const hasNotKey = !keys.includes(prop[0])
            const hasNotValue = !values.includes(prop[1])
            return hasNotKey && hasNotValue
        }),
    )
}
