/**
 * Crea un nuevo objeto con solo las propiedades especificadas de un objeto original.
 *
 * La función `pick` toma un objeto y un conjunto de claves y devuelve un nuevo objeto que contiene solo las propiedades
 * del objeto original cuyas claves estén en el conjunto proporcionado.
 *
 * @param object El objeto original del cual se extraerán las propiedades.
 * @param keys Un arreglo de claves que deben ser seleccionadas del objeto original.
 * @returns Un nuevo objeto que contiene solo las propiedades cuyo nombre está en `keys`.
 *
 * @template T - El tipo del objeto original, el cual debe ser un objeto (es decir, `T extends object`).
 * @template K - Un subconjunto de las claves de `T`, es decir, las claves que se seleccionarán del objeto.
 *
 * @example
 * const person = { name: 'John', age: 30, country: 'USA' };
 * const picked = pick(person, ['name', 'country']);
 * console.log(picked);  // { name: 'John', country: 'USA' }
 */
export function pick(obj, keys) {
    const result = {}
    const _keys = Array.isArray(keys) ? keys : [keys]
    for (const key of _keys) {
        if (key in obj) {
            result[key] = obj[key]
        }
    }
    return result
}
