import { Route, Routes } from 'react-router-dom'
import useRoutesByRole from 'hooks/useRoutesByRole'
import Reports from 'Pages/Reports'
import ReportsFacturas from 'Pages/ReportsFacturas'
import Sales from 'Pages/Sales'
import KardexValores from 'Pages/KardexValores'
import ReceiptSearch from 'Pages/ReceiptSearch'
import ChartAccount from 'Pages/ChartAccount'
import Locking from 'Pages/Locking'
import SuccessfulPayment from 'Pages/SuccessfulPayment'
import LastMovement from 'Pages/LastMovement'
import Facturas from 'Pages/Facturacion/Facturas'
import AnularFacturas from 'Pages/Facturacion/Anular'
import LoadingSection from 'Pages/LoadingSection'
import Home from 'Pages/Home'
import FacturaPDF from 'Pages/Facturacion/FacturaPDF'
import ComprobantePdf from 'Pages/ComprobantePdf'
import ListUsuario from 'Pages/contabilidad/usuario/ListUsuario'
import ListFondo from 'Pages/contabilidad/fondo/ListFondo'
import FormFondo from 'Pages/contabilidad/fondo/FormFondo'
import ListAllFondo from 'Pages/contabilidad/fondo/ListAllFondo'
import { verifyAccess } from 'utils/verifyAccess'
import { Ayuda } from 'Pages/contabilidad/Ayuda'
import ClasesGasto from 'Pages/presupuestos/ClasesGasto'
import UpdatePersona from 'Pages/recaudaciones/UpdatePersona'
import ObjetosGasto from 'Pages/presupuestos/ObjetosGasto'
import ReporteValorados from 'Pages/recaudaciones/ReporteValorados'
import ItemsGasto from 'Pages/presupuestos/ItemsGasto'
import VentaValores from 'Pages/recaudaciones/VentaValores/VentaValores'

// Datos de las rutas, par Ruta -> Datos de ruta
const pageRoutes = {
    sales: { path: '/sales', element: <Sales />, exact: true },
    reports: { path: '/reports', element: <Reports /> },
    receiptSearch: { path: '/receiptSearch', element: <ReceiptSearch /> },

    facturas: { path: '/facturas', element: <Facturas />, exact: true },
    anularFacturas: { path: '/anularFacturas', element: <AnularFacturas /> },
    reportsFacturas: { path: '/reportsFacturas', element: <ReportsFacturas /> },

    kardexValores: { path: '/kardexValores', element: <KardexValores /> },
    chartaccount: { path: '/chartaccount', element: <ChartAccount />, exact: true },
    locking: { path: '/locking', element: <Locking /> },
    'last-movement': { path: '/last-movement', element: <LastMovement /> },
}

const recaudacionesRoutes = [
    {
        path: 'recaudaciones/persona/update',
        element: <UpdatePersona />,
        expect: true,
        required: ['admin'],
    },
    {
        path: 'recaudaciones/valorados/venta',
        element: <VentaValores />,
        expect: true,
        // required: ['admin'],
    },
    {
        path: 'recaudaciones/valorados/reportes',
        element: <ReporteValorados />,
        expect: true,
        required: [
            'AdministradorRecaudaciones',
            'ReportesRecaudaciones',
            'Fiscalizador',
            'ReportesRecaudacionesDA',
            'JefeCajerosValorados',
            'JefeCajerosCajaCentral',
            'CajeroValorados',
            'CajeroCajaCentral',
            'dtic',
        ],
        operator: 'OR',
    },
]
const contabilidadRoutes = [
    {
        path: 'contabilidad/usuario',
        element: <ListUsuario />,
        exact: true,
        required: ['AdministradorContabilidad', 'AdministradorContabilidadDA'],
        operator: 'OR',
    },
    {
        path: 'contabilidad/fondo',
        element: <ListAllFondo />,
        required: ['AdministradorContabilidad', 'AdministradorContabilidadDA'],
        operator: 'OR',
        exact: true,
    },
    { path: 'contabilidad/fondo-rotatorio', element: <ListFondo tipo='R' />, required: ['BeneficiarioFondoRotatorio'] },
    { path: 'contabilidad/fondo-avance', element: <ListFondo tipo='A' />, required: ['BeneficiarioFondoAvance'] },
    {
        path: 'contabilidad/fondo/:id',
        element: <FormFondo />,
        required: [
            'BeneficiarioFondoRotatorio',
            'BeneficiarioFondoAvance',
            'AdministradorContabilidad',
            'AdministradorContabilidadDA',
        ],
        operator: 'OR',
    },
    {
        path: 'contabilidad/fondo/update/:id',
        element: <FormFondo tipo='update' />,
        required: ['BeneficiarioFondoRotatorio', 'BeneficiarioFondoAvance'],
        operator: 'OR',
    },
    {
        path: 'contabilidad/beneficiario/ayuda',
        element: <Ayuda />,
        required: ['BeneficiarioFondoRotatorio', 'BeneficiarioFondoAvance'],
        operator: 'OR',
    },
]
const presupuestosRoutes = [
    {
        path: 'presupuestos/clase-gasto',
        element: <ClasesGasto />,
        exact: true,
        required: ['AdministradorPresupuestos'],
    },
    {
        path: 'presupuestos/objeto-gasto',
        element: <ObjetosGasto />,
        exact: true,
        required: ['AdministradorPresupuestos'],
    },
    {
        path: 'presupuestos/item-gasto',
        element: <ItemsGasto />,
        exact: true,
        required: ['AdministradorPresupuestos'],
    },
    {
        path: 'presupuestos/fondo',
        element: <ListAllFondo tipo='PRESUPUESTOS' />,
        required: ['AdministradorPresupuestos', 'ReportesPresupuestosDA'],
        operator: 'OR',
    },
    {
        path: 'presupuestos/fondo/:id',
        element: <FormFondo />,
        required: ['AdministradorPresupuestos', 'ReportesPresupuestosDA'],
        operator: 'OR',
    },
]

export default function PrivateRoutes() {
    const routes = useRoutesByRole()

    const buildRoutes = (route, index) => {
        const { children, required, operator, ...props } = route
        return children ? (
            <Route key={route.path ?? `${index}-${children.length}`} {...props}>
                {children.map(buildRoutes)}
            </Route>
        ) : (
            verifyAccess(required, operator) && <Route key={route.path ?? (route.index ? '/' : index)} {...props} />
        )
    }

    return (
        <Routes>
            <Route index element={<LoadingSection />} />
            <Route path='/inicio' element={<Home />} />

            {routes.map((route, index) => (pageRoutes[route] ? <Route key={index} {...pageRoutes[route]} /> : null))}

            {contabilidadRoutes.map(buildRoutes)}
            {presupuestosRoutes.map(buildRoutes)}
            {recaudacionesRoutes.map(buildRoutes)}

            <Route path='/factura/:id' element={<FacturaPDF />} />
            <Route path='/comprobante/:id' element={<ComprobantePdf />} />
            <Route path='/pago_terminado' element={<SuccessfulPayment />} />
            <Route path='*' element={<LoadingSection />} />
        </Routes>
    )
}
