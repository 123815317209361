export function getLeafColumns(columns) {
    return columns.reduce((carry, col) => {
        if (col.columns) {
            carry.push(...getLeafColumns(col.columns))
        } else {
            carry.push(col)
        }
        return carry
    }, [])
}
